<template>
  <div class="grid">
    <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
            >
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                {{ $t('dept') }}
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
            >
              <Button
                iconPos="right"
                :label="$t('add')"
                icon="pi pi-plus"
                v-tooltip.bottom="'Add'"
                @click="add_cat"
                size="small"
                style="
                  box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                  border-color: #00b0b0 !important;
                  background-color: #00b0b0 !important;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">

      <div class="card" id="custom_card">
        <div class="grid p-fluid">
          <div class="col-12 md:col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <InputText
                    format="text"
                    v-model="search_cat"
                    @input="get_list(1)"
                  >
                  </InputText>
                  <label>{{ $t('search_dept') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-8">
                <i class="pi pi-circle-fill" style="font-size: 1rem; color: #e30000!important; cursor: pointer;" ></i>&nbsp;
                <span  style="font-weight: 600; margin-left: 2px; margin-right: 2px; color: #6b719b">
                    {{ $t('open_ticket') }}
                </span>
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #0000e3!important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b">{{ $t('InProgress_Ticket') }}</span>
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #009700!important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b">{{$t('close_ticket')}}</span>
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #808080 !important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b"> {{ $t('total_ticket') }}</span>
                </div>

              <div class="field col-12 md:col-2" v-if="user_type=='Super Admin'">
                <span class="p-float-label">
                  <Dropdown
                    id="client_dropdown"
                    v-model="client_selected"
                    :options="client_dropdown"
                    :value="client_dropdown"
                    optionLabel="name"
                    filter
                    v-on:change="get_list(1)"
                  ></Dropdown>
                  <label for="Client">Client</label>
                </span>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div style="text-align: right; font-weight: bold ; font-size: 12px; margin-right: 35px;">
              <span>{{ $t('total_dept') }} : </span>{{ dataviewValue.length }}
          </div>
        </div>  
        <!-- :rows="9" -->
        
        <DataView
          :value="dataviewValue"
          :layout="layout"
          :paginator="true"
           :rowsPerPageOptions="[8, 16, 24, totalRecords]"
          :rows="rowsPerPage"
          :sortOrder="sortOrder"
          :sortField="sortField"
          
        >
          <template #grid="slotProps">
            <div class="grid grid-nogutter">
              <div
                class="col-12 md:col-4"              
              >
                <div
                  style="height: 120px;width: 280px;"
                  class="card m-3 border-1 surface-border zoom"
                  id="custom_card_left"
                  @mouseover="showButtons(index)"
                  @mouseout="hideButtons(index)"
                  :style="{ 'border-left': '5px solid '+ slotProps.data.color + ' !important' }"
                  
                >
                  <div class="flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <p
                        style="
                          color: #6b719b;
                          font-size: 1rem;
                          font-weight: 600;
                          cursor: pointer;
                        "
                        @click="view_categories(slotProps.data)"
                      >
                        {{ slotProps.data.name }}
                      </p>
                    </div>
                  </div>
                  <div class="flex align-items-center justify-content-between" style="margin-top: 5px;">
                    <div class="flex align-items-center">
                      
                      <p
                        style="
                          color: #6b719b;
                          font-size: 0.875rem;
                          font-weight: 300;
                        "
                      >
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:red; cursor: pointer;"  v-tooltip="$t('open_ticket')" @click="go('/tickets',1,slotProps.data._id)"></i>&nbsp;
                      {{ slotProps.data.open_ticket?slotProps.data.open_ticket:'0' }} &nbsp;
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:blue; cursor: pointer;" v-tooltip="$t('InProgress_Ticket')" @click="go('/tickets',2,slotProps.data._id)" ></i>&nbsp;
                      {{ slotProps.data.inprogress_ticket?slotProps.data.inprogress_ticket:'0' }} &nbsp;
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:green; cursor: pointer;" v-tooltip="$t('close_ticket')" @click="go('/tickets',3,slotProps.data._id)"></i>&nbsp;
                      {{ slotProps.data.closed_ticket?slotProps.data.closed_ticket:'0' }} &nbsp;
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:grey; cursor: pointer;" v-tooltip="$t('total_ticket')" @click="go('/tickets',null,slotProps.data._id)"></i>&nbsp;
                      {{ slotProps.data.allticket?slotProps.data.allticket:'0' }} 
                      </p>
                    </div>
                  </div>

                  <div class="flex justify-content-end">
                    <Button
                      v-show="showButton[index]"
                      class="p-button-rounded p-button-success p-button-text"
                      @click="edit_cat(slotProps.data)"
                      icon="pi pi-pencil"
                      size="samll"
                    ></Button>
                   
                    <Button
                      v-show="showButton[index]"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash"
                      size="samll"
                      @click="delete_toggle(slotProps.data)"
                    ></Button>

                    <OverlayPanel ref="del" >
                            <div class="flex flex-column gap-3 w-25rem">
                              <h5>{{ $t('delete_department') }} </h5>

                              <div class="col-12">
                                <div class="p-fluid formgrid grid">
                                  <div class="field col-12 md:col-12">
                                    <span class="p-float-label">
                                      <Textarea
                                          format="text"
                                          v-model="delete_remark"
                                        >
                                      </Textarea>
                                      <label>{{$t('remark')}}</label>
                                  </span>
                                  </div>

                                  <div class="field col-12 md:col-4">
                                    <Button :label="$t('delete')" @click="delete_product"  class="p-button-primary btn_light_blue"/>
                                  </div>
                                  <div class="field col-12 md:col-4">
                                    <Button :label="$t('cancel')" @click="Cancel_delete"  class="p-button-danger btn_red"/>
                                  </div>

                                </div>
                              </div>

                            </div>
                      </OverlayPanel>
                    <!-- ------------ -->
                  </div>
                
                </div>
              </div>
            </div>
          </template>
        </DataView>

        <!-- <div class="per-page-and-pagination">
          <div class="per-page-dropdown">
            <label for="perPage">per page : </label>
            <Dropdown @change="window_up()" :options="perPageOptions" v-model="rowsPerPage" />
          </div>
        </div> -->


        
        
      </div>
    </div>
    <Dialog
          v-model:visible="add_cat_model"
		      :breakpoints="{'960px': '75vw'}"
          :style="{ width: '35vw' }"
          :header="$t('add_dept')"
          :modal="true"
		      position="top"
        >
        <div class="col-12">
          <div class="p-fluid formgrid grid" style="margin-top: 2%;">
            <div class="field col-12 md:col-12" style="margin-top: 1%;">
              <span class="p-float-label">
                <InputText id="ticket_title" type="text" v-model="cat_title" />
                <label for="name">{{ $t('name') }}</label>
              </span>
            </div>
            <div class="field col-12 md:col-12" style="margin-top: 1%;">
              <label for="cp-hex" class="font-bold block mb-2"> {{ $t('color') }} </label>
              <ColorPicker v-model="colorHEX" inputId="cp-hex" format="hex" class="mb-3" />
            </div>

            <div class="field col-12 md:col-12" style="margin-top: 1%;">
        <label for="total_users" class="font-bold block mb-2">{{ $t('total_users') }}</label>
        <p id="total_users" class="text-lg font-medium">{{ totalUsers }}</p>
      </div>
          </div>
        </div>

        <template #footer>
		      <Button :label="$t('save')" @click="save_cat" icon="pi pi-check" class="p-button-success"/>
		    </template>
    </Dialog>
  </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
export default {
  data() {
    return {
      rowsPerPage:8,
      perPageOptions: [8, 16, 24, 100],
      totalRecords:0,
      temp_product_id:"",
      delete_remark:"",
      search_cat:"",
      closed_tickets: 0,
      cat_title:"",
      colorHEX:"6b719b",
      isLoadingModel: false,
      fullPage: false,
      dataviewValue: [],
      layout: "grid",
      sortKey: null,
      sortOrder: null,
      sortField: null,
      user_type: "",
      client_id: "",
      showButton: [],
      add_cat_model:false,
      id:"",

      client_dropdown:[],
      client_selected:"",
      clientid:"",
    };
  },
  productService: null,
  watch:{
    // async rowsPerPage() 
    // {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  } ,
  created() {
    
  },
  async mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.client_id = localStorage.getItem("client_id");
    await this.get_list();
    await this.getClientMaster();
  },
  methods: {
    window_up()
    {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async view_categories(item)
    { 
      await localStorage.setItem('categoryDetails','');
      await localStorage.setItem('categoryDetails',JSON.stringify(item));
     // this.$router.push({ name: "categoriesdetails" });
      this.$router.push({ name: "tickets" });
    },
    async confirmDeleteProduct(product) 
    {
      this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to Delete Department?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {


                    var payload=
                    {
                      "client_id":"65a4f82f6177e69880ece5d6",
                      "user_id":localStorage.getItem("id"),
                      "category_id":product._id

                    }
        
                    this.isLoadingModel = true;
                    var promise = apis.deleteCategory(payload);
                    promise.then((response) => {
                        this.isLoadingModel = false;
                        if (response.data.status==true) 
                        {
                            this.$swal(response.data.message);
                             this.get_list();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            
                        }
                        else
                        {
                            this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
                        }
                    });

                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: 'Cancel', detail: 'You have Cancelled', life: 3000 });
                }
            });
        
    },
    go(path, ticketType, category) {
        
        localStorage.setItem("mis_status_group",ticketType);
        localStorage.setItem("category",category); 
        this.$router.push(path);
      },


    delete_toggle(data) 
    { 
      this.temp_product_id=data._id;
      this.$refs.del.toggle(event);
    },

    delete_product(){
      if (this.delete_remark==null || this.delete_remark=="" || this.delete_remark==undefined) 
        {
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: this.$t('remark_empty_error'),
              life: 3000,
          });
          return false;
        }

      else
      {
        var payload=
        {
          "client_id":"65a4f82f6177e69880ece5d6",
          "user_id":localStorage.getItem("id"),
          "category_id":this.temp_product_id,
          "remark":this.delete_remark
        }

        this.isLoadingModel = true;
        var promise = apis.deleteCategory(payload);
        promise.then((response) => {
            this.isLoadingModel = false;
            if (response.data.status==true) 
            {
                this.$swal(response.data.message);
                  this.get_list();
                  this.temp_product_id="";
                  this.delete_remark="";
                window.scrollTo({ top: 0, behavior: 'smooth' });
                
            }
            else
            {
                this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
            }
        });
      }
    },

    Cancel_delete(){
      this.temp_product_id="";
      this.delete_remark="";
      this.$refs.del.hide();
    },
    async save_cat()
    {
      var name_regex = /^\s*$/;

      if (this.cat_title=="" || !this.colorHEX) 
      {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: this.$t('department_empty_error'),
          life: 3000,
        });
        return false;
      }

      if (this.cat_title) 
      {   
          if (name_regex.test(this.cat_title)) 
          {
              this.$toast.add({
              severity: "error",
              summary: "error",
              detail: this.$t('department_invalid_error'),
              life: 3000,
            });
            return false;
          }                
      }

      var color = '#'+this.colorHEX;
      let details=
      {
          
          "client_id":"65a4f82f6177e69880ece5d6",
          "name":this.cat_title,
          "color":color,
          "status":"Active",
          "user_id":localStorage.getItem("id"),
		}
      if (this.id) 
      {
        details['id']=this.id;
        
      } 
      
     
      this.isLoadingModel=true;
				var promise = apis.categoryAddEdit(details);
					promise.then((response) => {
            this.isLoadingModel=false;
						this.$swal(response.data.message);
            this.add_cat_model=false;
            this.get_list();
        	
					});
    },
    async add_cat()
    { this.id="";
      this.cat_title ="";
      this.colorHEX  ="6b719b";
      this.add_cat_model=true;
    },
    async edit_cat(data)
    { 
      this.id=data._id;
      this.cat_title  = data.name;
      
      var color       = data.color;
      this.colorHEX   = color ? color.slice(1) : '6b719b';

      this.add_cat_model=true;
    },
    
    async getClientMaster() {
      var data = {
          limit: 10000,
          page_no: 1,
          count: false,
          user_id: localStorage.getItem("id"),
      };
     
      var promise = apis.getclientist(data);
      promise.then((response) => {
       this.client_dropdown = response.data.data;
      });
    },
 
    async get_list() {
      this.clientid = "";
      this.clientid = this.client_selected._id;
     
      if (this.clientid=='' || this.clientid==undefined) 
      {
        this.clientid=localStorage.getItem("client_id");
      }

      var data = {
        // client_id: "65a4f82f6177e69880ece5d6",
        client_id: this.clientid,
        search:this.search_cat,
      
      };
      this.isLoadingModel = true;
      var promise = apis.categoryListmaster(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        // this.dataviewValue = [];
       this.dataviewValue = response.data.data;
       
      });
    },
    showButtons(index) {
      //this.$set(this.showButton, index, true);
      this.showButton[index] = true;
    },
    hideButtons(index) {
      //this.$set(this.showButton, index, false);
      this.showButton[index] = false;
    },
  },
};
</script>
<style scoped >
@import "../assets/demo/badges.scss";
#custom_card {
  border-top: 3px solid #6b719b;
}
#custom_card_left {
 
  box-shadow:rgba(0, 0, 0, 0.1) 0px 8px 12px 0px !important;
}
.zoom {
  transform-origin: 0% 0% 1% 1%;
  transition: transform 1s, filter 1s ease-in-out;
}
/* The Transformation */
.zoom:hover {
  transform: scale(1.1);
}

.border-info {
    border-left: 5px solid  #0dcaf0 !important;
}

.per-page-and-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.per-page-dropdown {
  margin-right: 20px;
}
</style>
