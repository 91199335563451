<template>
    <div style="background-color: #f4f6f9">
      <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <ConfirmPopup></ConfirmPopup>
      <div id="card1" class="card d-flex justify-content-center">
        <div class="py-4 px-4 mx-0 lg:mx-12">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6" style="margin-top: -1.5rem">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <h4 style="color: #6b719b; font-size: 20px; font-weight: 700">
                  Edit Ticket
                </h4>
              </div>
            </div>
            <div class="col-12 md:col-6" style="margin-top: -1.5rem">
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <Button
                  class="p-button-primary"
                  size="small"
                  label="Back"
                  v-tooltip.bottom="'back'"
                  @click="Goback"
                />&nbsp;
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText id="ticket_title" type="text" v-model="title" />
                <label for="ticket_title">Ticket Title</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <Dropdown
                  id="Category"
                  v-model="ticket_category_selected"
                  :options="ticket_category_dropdown"
                  :value="ticket_category_dropdown"
                  optionLabel="name"
                  filter
                ></Dropdown>
                <label for="Category">Category</label>
              </span>
            </div>
            <div class="field col-12 md:col-6" style="margin-top: 1%">
              <span class="p-float-label">
                <Dropdown
                  id="type"
                  v-model="ticket_type_selected"
                  :options="ticket_type_dropdown"
                  :value="ticket_type_dropdown"
                  optionLabel="name"
                  filter
                ></Dropdown>
                <label for="type">Ticket Type</label>
              </span>
            </div>
            <div class="field col-12 md:col-2" style="margin-top: 1%">
              <FileUpload
                class="p-button-primary"
                mode="basic"
                name="model[]"
                :auto="true"
                :custom-upload="true"
                choose-label="Attachment"
                @uploader="handleFileUpload($event)"
              />
            </div>
            <div class="field col-1 md-2" style="margin-top: 1%">
              <Button
                  class="p-button-danger"
                  mode="basic"
                  @click="cancelFileUpload()" 
                  style="box-shadow: 0 2px 6px rgba(0,176,176,.549); border-color: #ff0000!important; background-color: #ff0000!important;"
                  >
                  Cancel <!-- Button label -->
              </Button>
          </div>
            <div class="field col-12 md:col-2" style="margin-top: 1%">
              <h4 style="color: #6b719b; font-size: 20px; font-weight: 600">
                Attachment : {{ attachments_count }}
              </h4>
            </div>
            <div class="field col-12 md:col-12">
              <h6 style="color: #6b719b; font-size: 15px; font-weight: 400">
                Description :
              </h6>
              <Editor v-model="description" editorStyle="height: 200px" />
            </div>
          </div>
        </div>
        <div class="col-12 md:col-12 d-flex text-center justify-content-center">
          <Button
            class="p-button-primary"
            @click="submit_ticket()"
            label="Save Ticket"
          />
        </div>
      </div>
      <ScrollTop />
    </div>
  </template>
  <script>
  import apis from "@/apis";
  import axios from "axios";
  export default {
    name: "EticketCustomerCreateTicket",
    data() {
      return {
        ticket_id:"",
        isLoadingModel: false,
        fullPage: true,
        type: "",
        ticket_type_dropdown: [],
        ticket_type_selected: "",
        category_id: "",
        ticket_category_dropdown: [],
        ticket_category_selected: "",
        title: "",
        attachments: [],
        attachments_count: 0,
        description: "",
        client_id: "",
        file_data: {
          filePath: "",
          contentType: "",
        },
      };
    },
    async mounted() {
      this.client_id = localStorage.getItem("client_id");
      this.client_id = "65a4f82f6177e69880ece5d6";
      await this.getTicketTypeMaster();
      await this.getTicketCategoryMaster();
      var local_data=[];
      if (localStorage.getItem("TicketDetails")) 
      {
        local_data = await JSON.parse(localStorage.getItem("TicketDetails"));
        var details = local_data[0];
        this.title=details.title;
        this.attachments=details.attachments;
        this.attachments_count=details.attachments.length;
        this.description=details.description;
        this.ticket_id=details.ticket_no;
        this.ticket_category_selected=details.ticket_category[0];
        this.ticket_type_selected=details.ticket_type[0];
        
      }

      

    },
    methods: {
      cancelFileUpload() {
      this.file_data = {
        filePath: "",
        contentType: "",
      };
      this.attachments = [];
      this.attachments_count = 0;
    },
      async Goback() {
        this.$router.push({ name: "tickets" });
      },
      async onClick() {
        this.$emit("redirect_to_login");
      },
      async submit_ticket() {
        var name_regex = /^\s*$/;
        
        if (this.title == "") {
          this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Tittle",
            life: 3000,
          });
          return false;
        }
        if (!/^[a-zA-Z\s]*$/.test(this.title)) {
        this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Please enter a valid title name containing only letters.",
            life: 3000
      });
      return false;
}
if (this.title.length>500) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Valid Tittle Name",
          life: 3000,
        });
        return false;
      }

        if (this.title) 
        {   
            if (name_regex.test(this.title)) 
            {
                    this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Valid Title",
                    life: 3000,
                });
                return false;
            }                
        }

        
      if (this.ticket_category_selected == "") {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Ticket Category",
          life: 3000,
        });
        return false;
      }

      if (this.ticket_type_selected == "") {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Ticket Type",
          life: 3000,
        });
        return false;
      }

      if (this.description == "") {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Description",
          life: 3000,
        });
        return false;
      }
      if (this.description.length>1000) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter valid Description",
          life: 3000,
        });
        return false;
      }

        this.$confirm.require({
          target: event.currentTarget,
          message: "Are you sure you want to save ticket?",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            //get category id
            this.category_id = this.ticket_category_selected._id;
            // get ticket type
            this.type = this.ticket_type_selected._id;
            var payload = {
              ticket_no:this.ticket_id,
              user_id: localStorage.getItem("id"),
              customer_id: localStorage.getItem("id"),
              title: this.title,
              category_id: this.category_id,
              type: this.type,
              attachments: this.attachments,
              description: this.description,
              client_id: localStorage.getItem("client_id"),
           };
            this.isLoadingModel = true;
            var promise = apis.EditTicket(payload);
            promise.then((response) => {
              this.isLoadingModel = false;
              if (response.data.status == true) {
                  this.$swal(response.data.message);
                this.reset_form();
                window.scrollTo({ top: 0, behavior: "smooth" });
                this.Goback();
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "ERROR!!!",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            });
          },
          reject: () => {
            this.$toast.add({
              severity: "error",
              summary: "Rejected",
              detail: "You have rejected",
              life: 3000,
            });
          },
        });
      },
      async reset_form() {
        this.file_data = {
          filePath: "",
          contentType: "",
        };
        this.title = "";
        this.category_id = "";
        this.type = "";
        this.attachments = [];
        this.attachments_count = 0;
        this.description = "";
        this.ticket_category_selected = "";
        this.ticket_type_selected = "";
      },
      async handleFileUpload(event) {
        var fileUp = event.files[0];
        var file = fileUp;
        this.file = file;
        var prfeix = await this.date_time_prefix();
        var filename = prfeix + "_" + file.name;
        this.file_data = {
          filePath: filename,
          contentType: file.type,
        };
        var promise = apis.upload_to_AWS(this.file_data);
        promise.then((response) => {
          axios
            .put(response.data.data, file, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": file.type,
              },
            })
            .then((response) => {
              this.attachments.push(
                "https://eticket-docs.s3.ap-south-1.amazonaws.com/" + filename
              );
              this.attachments_count = this.attachments_count + 1;
            });
        });
      },
      async date_time_prefix() {
        // Get the current date and time
        const currentDateTime = new Date();
        // Format the date as YYYY_MM_DD
        const formattedDate = currentDateTime
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "_");
        // Format the time as HH_mm_ss
        const formattedTime = currentDateTime
          .toTimeString()
          .split(" ")[0]
          .replace(/:/g, "_");
        // Construct the final string
        const resultString = `${formattedDate}_${formattedTime}`;
        return resultString;
      },
      async getTicketTypeMaster() {
        var data = {
          client_id: "65a4f82f6177e69880ece5d6",
          user_id: localStorage.getItem("id"),
        };
        this.isLoadingModel = true;
        var promise = apis.TicketTypeMaster(data);
        promise.then((response) => {
          this.isLoadingModel = false;
          this.ticket_type_dropdown = response.data.data;
        });
      },
      async getTicketCategoryMaster() {
        var data = {
          client_id: "65a4f82f6177e69880ece5d6",
          user_id: localStorage.getItem("id"),
        };
        this.isLoadingModel = true;
        var promise = apis.TicketCategoryMaster(data);
        promise.then((response) => {
          this.isLoadingModel = false;
          this.ticket_category_dropdown = response.data.data;
        });
      },
    },
  };
  </script>
  <style scoped>
  #card1 {
    background-color: #fff;
    border-top: 3px solid #6b719b;
  }
  .link-style {
    color: #6b719b;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
  .link-style:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  @media screen and (min-width: 992px) {
  }
  </style>