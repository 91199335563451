<template>
    <div>
        <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-8 justify-content-center" style="background-color: #F4F6F9;">
            <loading
                :active="isLoadingModel"
                :can-cancel="true"
                color="#274461"
                :is-full-page="fullPage"
                ></loading>
                <Toast />
                <ConfirmPopup></ConfirmPopup>
                <div id="card1" class="card d-flex justify-content-center">
                    <div class="col-12 md:col-12">
                        <h4 class="d-flex text-center" style="color: #6b719b; font-size: 30px; font-weight: 800">FAQs</h4>
                    </div>
                    <div class="col-12">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-4"></div>
                            <div class="field col-12 md:col-5">
                                <span class="p-float-label">
                                    <InputText id="ticket_title" type="text" v-model="search" @input="search_list()"/>
                                    <label for="ticket_title">Search</label>
                                 </span>
                            </div>
                        </div>
                    </div>
                 
                    <div class="col-12 md:col-12">
                        <Accordion >
                            <AccordionTab :header="item.name" v-for="(item, index) in list" :key="index">
                                <p v-html="item.description" class="line-height-3 m-0" style="color: #6b719b;"></p>
                            </AccordionTab>
                        </Accordion>
                    </div>
                  
                </div>
        </div>
    </div>
</template>


<script>
import apis from "@/apis";
import axios from "axios";
export default {
    name: 'EticketFAQs',

    data() {
        return {
            search:"",
            isLoadingModel: false,
            fullPage: true,
            list:[],
        };
    },

    mounted() {
        this.search_list();
    },

    methods: {
        search_list()
        {
            var data = {    
                            client_id: "65a4f82f6177e69880ece5d6",
                            search:this.search,
                            user_id: localStorage.getItem("id"),
                       };
                        this.isLoadingModel = true;
                        var promise = apis.getFaqMaster(data);
                        promise.then((response) => {
                            this.isLoadingModel = false;
                            this.list = response.data.data;
                            
                        });
        }
    },
};
</script>

<style scoped>
#card1 {
    background-color: #fff;
    border-top: 3px solid #6b719b;
}
.lg\:px-8 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
}
</style>