<template>
    <div class="grid">
      <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <div class="col-12 sticky">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                  {{ $t('ward_list') }}
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
               
                
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
              <Button
                iconPos="right"
                :label="$t('add')"
                icon="pi pi-plus"
                v-tooltip.bottom="'Add'"
                @click="add_cat"
                size="small"
                style="
                  box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                  border-color: #00b0b0 !important;
                  background-color: #00b0b0 !important;
                "
              />
             
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card" id="custom_card">
          <div class="grid p-fluid">
            <div class="col-12 md:col-12">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <span class="p-float-label">
                    <InputText
                      format="text"
                      v-model="ward_no"
                      @input="search(1)"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                         maxlength="10"
                    >
                    </InputText>
                   
                    <label>{{ $t('search_ward_no') }}</label>
                  </span>
                </div>
              
                <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown
                    id="ward_number"
                    v-model="wardname"
                    :options="ward_number_dropdown"
                      :value="ward_number_dropdown"
                    optionLabel="name"
                    filter
                    v-on:change="search(1)"
                  />
                  <label for="ward_number">{{ $t('ward_number') }}</label>
                </span>
              </div>
              <div class="field col-2 md:col-2">
                
                <Button
                  icon="pi pi-refresh"
                  v-tooltip.bottom="$t('refresh')"
                  @click="search(2)"
                  class="p-button-danger"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                  border-color: #fc544b !important;
                  background-color: #fc544b !important;"
                />
              </div>
              <div class="field col-12 md:col-6">
                <i class="pi pi-circle-fill" style="font-size: 1rem; color: #e30000!important; cursor: pointer;" ></i>&nbsp;
                <span  style="font-weight: 600; margin-left: 2px; margin-right: 2px; color: #6b719b">
                  {{ $t('open_ticket') }}
                </span>
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #0000e3!important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b">{{ $t('InProgress_Ticket') }}</span>
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #009700!important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b">{{$t('close_ticket')}}</span>
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #808080 !important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b">{{ $t('total_ticket') }}</span>
                </div>
              </div>
              <div class="row">
          <div style="text-align: right; font-weight: bold ; font-size: 15px; margin-right: 35px;">
              <span>{{ $t('total_ward') }} : </span>{{ totalRecords }}
          </div>
        </div>
            </div>
          </div>
          

<DataView
:lazy="true"
      :value="products"
      :layout="layout"
      :paginator="true"
       @page="onPage($event)"
       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 30, totalRecords]"
      :rows="10"
      :sortOrder="sortOrder"
      :sortField="sortField"
      :filters="filters"
:totalRecords="totalRecords"
 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"

    
           
          
    >
      <template #grid="{ data }">
        <div class="grid grid-nogutter">
          <div
            :key="data._id"
            class="col-12"
          >
            <div
              class="card m-3 border-1 surface-border zoom"
              style="height: 130px; width: 280px;"
              id="custom_card_left"
              @mouseover="showButtons(index)"
                  @mouseout="hideButtons(index)"
                  :style="{ 'border-left': '5px solid red !important' }"
            >
            <div class="grid grid-nogutter row">
              <div class="col-12 md:col-2">
                
            </div>
            <div class="col-12 md:col-8">
                {{ data.name }}
              </div>
              <div class="col-12 md:col-2">
                <span v-if="data.status=='Active' || data.status=='active'">
          <badge class="btn_green" :value="data.status" severity="success"></badge>
        </span>
        <span v-else-if="data.status=='InActive' || data.status=='In Active'">
          <badge class="btn_red" :value="data.status" severity="danger"></badge>
        </span>
              </div>
            </div>

              <div class="flex align-items-center justify-content-between mt-4" style="margin-top: 7px;">
                    <div class="flex align-items-center">
                      
                      <p
                        style="
                          color: #6b719b;
                          font-size: 0.875rem;
                          font-weight: 300;
                        "
                      >
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:red; cursor: pointer;"  v-tooltip="$t('open_ticket')" @click="go('/tickets',1,data.ward_no)"></i>&nbsp;
                      {{ data.open_ticket?data.open_ticket:'0' }} 
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:blue; cursor: pointer;" v-tooltip="$t('InProgress_Ticket')" @click="go('/tickets',2,data.ward_no)" ></i>&nbsp;
                      {{ data.inprogress_ticket?data.inprogress_ticket:'0' }} 
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:green; cursor: pointer;" v-tooltip="$t('close_ticket')" @click="go('/tickets',3,data.ward_no)"></i>&nbsp;
                      {{ data.closed_ticket?data.closed_ticket:'0' }} 
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:grey; cursor: pointer;" v-tooltip="$t('total_ticket')" @click="go('/tickets',null,data.ward_no)"></i>&nbsp;
                      {{ data.allticket?data.allticket:'0' }} 
                      </p>
                    </div>
                  </div>
              <div class="flex justify-content-end">
                <Button
                  v-show="showButton[index]"
                  class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-pencil"
                  size="small"
                  @click="editProduct(data)"
                ></Button>
                <Button
                  v-show="showButton[index]"
                  class="p-button-rounded p-button-danger p-button-text"
                  icon="pi pi-trash"
                  size="small"
                  @click="delete_toggle(data)"
                ></Button>
              </div>
              <OverlayPanel ref="del" >
                <div class="flex flex-column gap-3 w-25rem">
                  <h5>{{ $t('delete_ward') }} </h5>

                  <div class="col-12">
                    <div class="p-fluid formgrid grid">
                      <div class="field col-12 md:col-12">
                        <span class="p-float-label">
                          <Textarea
                              format="text"
                              v-model="delete_remark"
                            >
                          </Textarea>
                          <label>{{$t('remark')}}</label>
                      </span>
                      </div>

                      <div class="field col-12 md:col-4">
                        <Button :label="$t('delete')" @click="deleteWard"  class="p-button-primary btn_light_blue"/>
                      </div>
                      <div class="field col-12 md:col-4">
                        <Button :label="$t('cancel')" @click="Cancel_delete"  class="p-button-danger btn_red"/>
                      </div>

                    </div>
                  </div>

                </div>
              </OverlayPanel>
            </div>
          </div>
        </div>
      </template>
    </DataView>
  
          <!-- <DataTable
            :loading="loading"
            ref="dt"
            :lazy="true"
            :totalRecords="totalRecords"
            :paginator="true"
            :value="products"
            v-model:selection="selectedProducts"
            :dataKey="columns[0]"
            :rows="limit"
            :filters="filters"
            @page="onPage($event)"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25, totalRecords]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            style="font-size: 12px"
            :rowHover="true"
            showGridlines
          >
            <template #empty>
              <b style="text-align: center">No records found.</b>
            </template>
            <template #header> -->
              <!-- <div
                class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
              >
                <h5 class="m-0"><b> FAQs Master</b></h5>
                <Button
                  icon="pi pi-refresh"
                  v-tooltip.bottom="'Refresh'"
                  @click="search(2)"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;"
                />
               
              </div> -->
              
              <!-- <div style="text-align: right; font-weight: bold">
                <span>Total Ward No:</span>{{ totalRecords }}
              </div>
            </template>
            <Column header="Status" style="min-width: 1rem ;text-align: center;" :field="columns[14]" >
            <template #body="{ data }"> 
              <span v-if="data.status=='Active' ||data.status=='active'"><badge class="btn_green" :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge  class="btn_red" :value=data.status severity="danger"></badge></span>
              
            <span v-else>-</span>
            </template>
          </Column>

          <Column header="Sr No" style="min-width: 1rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
           
          <Column header="Ward No" style="min-width: 2rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.ward_no }}

            </template>
          </Column>
          <Column 
                  header="Ward Name" 
                  style="min-width: 2rem; text-align: center;" 
                  :headerStyle="{ textAlign: 'center' }"
                >
            <template #body="{ data}">
            
              {{ data.name }}

            </template>
          </Column>

          
          <Column header="Action" style="min-width: 2rem;text-align: center;">
            <template #body="slotProps"> -->
              <!-- <Button
             
                icon="pi pi-eye"
                v-tooltip.bottom="'View'"
                class="p-button-rounded p-button-success mr-2 btn_green"
                @click="viewProduct(slotProps.data)"
              /> -->
              <!-- <Button
                  icon="pi pi-pencil"
                  title="Edit Client"
                  v-tooltip.bottom="'Edit'"
                  class="p-button-rounded p-button-help mr-2 btn_yellow"
                  @click="editProduct(slotProps.data)"
                /> -->
              <!-- <Button
                icon="pi pi-trash"
                v-tooltip.bottom="'Delete'"
                class="p-button-rounded p-button-warning mt-2 btn_red"
                @click="confirmDeleteProduct(slotProps.data)"
              /> -->

              <!-- <Button
                icon="pi pi-trash"
                v-tooltip.bottom="'Delete'"
                class="p-button-rounded p-button-warning mt-2 btn_red"
                @click="delete_toggle(slotProps.data)"
              />

              <OverlayPanel ref="del" >
                <div class="flex flex-column gap-3 w-25rem">
                  <h5>Delete Ward </h5>

                  <div class="col-12">
                    <div class="p-fluid formgrid grid">
                      <div class="field col-12 md:col-12">
                        <span class="p-float-label">
                          <Textarea
                              format="text"
                              v-model="delete_remark"
                            >
                          </Textarea>
                          <label>Remark</label>
                      </span>
                      </div>

                      <div class="field col-12 md:col-4">
                        <Button label="Delete" @click="deleteWard"  class="p-button-primary btn_light_blue"/>
                      </div>
                      <div class="field col-12 md:col-4">
                        <Button label="Cancel" @click="Cancel_delete"  class="p-button-danger btn_red"/>
                      </div>

                    </div>
                  </div>

                </div>
              </OverlayPanel>
            </template>
          </Column>
          </DataTable> -->
          
          <Dialog
        v-model:visible="productDialog"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '45vw' }"
        :header="$t('edit_ward')"
        :modal="true"
        position="top"
      >
        <div class="col-12">
          <div class="p-fluid formgrid grid" style="margin-top: 2%">
            <div class="field col-12 md:col-6" style="margin-top: 2%">
        <span class="p-float-label">
        
          <InputText id="ward_number" type="text" v-model="product.ward_no"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10" required />
              <label for="ward_number">{{ $t('ward_number') }} </label>
        </span>
      </div>
            <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="product.name"
                  />
                  <label for="ticket_title">{{ $t('ward_name') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                    <Dropdown 
                    id="status" 
                    v-model="status_selected" 
                    :options="status_dropdownItems" 
                    optionLabel="name" 
                    filter
                    :disabled="!product._id"
                    :class="{ 'p-invalid': submitted && !status_dropdownItem }">
                    </Dropdown>
                  <label for="ticket_title">{{$t('status')}}</label>
                </span>
              </div>
          </div>
        </div>
  
        <template #footer>
          <Button
            :label="$t('save')"
            @click="save_status"
            icon="pi pi-check"
            class="p-button-success"
          />
        </template>
      </Dialog>

          <Dialog
            v-model:visible="add_cat_model"
            :breakpoints="{'960px': '75vw'}"
            :style="{ width: '35vw' }"
            :header="$t('add_ward')"
            :modal="true"
            position="top"
            >
  <div class="col-12">
    <div class="p-fluid formgrid grid" style="margin-top: 2%;">

       
      <div class="field col-12 md:col-12" style="margin-top: 1%;">
        <span class="p-float-label">
        
          <InputText id="ward_number" type="text" v-model="ward_number"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10" required />
              <label for="ward_number">{{ $t('ward_number') }}<b style="color: red">*</b> </label>
        </span>
      </div>
      <div class="field col-12 md:col-12" style="margin-top: 1%;">
           
          
          </div>
    
<div class="field col-12 md:col-12" style="margin-top: 1%;">
    <span class="p-float-label">
      <InputText
        id="ward_name"
        v-model="ward_name"
        @input="validateInput"
      />
  
      <label for="ward_name">{{ $t('ward_name') }}<b style="color: red">*</b></label>
    </span>
  
  </div>
<div class="field col-12 md:col-12" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown id="status" v-model="status_dropdownItem" :options="status_dropdownItems" 
                            optionLabel="name" filter
                            :class="{ 'p-invalid': submitted && !status_dropdownItem }"></Dropdown>
                        <label for="status">{{ $t('status') }} <b style="color: red">*</b></label>
                        </span>
                    </div>
    </div>
  </div>

  <template #footer>

    <Button :label="$t('save')" @click="save_cat" icon="pi pi-check" class="p-button-success" />&nbsp;
    <Button 
      :label="$t('cancel')" 
      @click="add_cat_model = false" 
      icon="pi pi-times" 
      class="p-button-secondary" 
    />
  </template>
</Dialog>

        </div>
      </div>
     
    
  
   
  
    
    </div>
  </template>
    <script>
  import { FilterMatchMode } from "primevue/api";
 
  
  import apis from "@/apis";
  import axios from "axios";
  export default {
    data() {
      return {
          
        layout: "grid",
      sortKey: null,
      sortOrder: null,
      sortField: null,
        page_no: 1,
        rowsPerPage:10,
        showButton: [],
         
        status_dropdownItems: [
                { name: "Active", value: "Active" },
                { name: "InActive", value: "InActive" },
                
            ],
            status_dropdownItem: null,
        isLoading: false,
        add_cat_model:false,
        isLoadingModel: false,
        loading: false,
        fullPage: true,
        products: null,
        ward_no:'',
        wardname:"",
        ward_name:"",
        ward_number:"",
        
        ward_number_dropdown:[],        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        lazyParams: {},
        product: {},
        selectedProducts: null,
        filters: {},
        offset: 1,
        limit: 10,
        columns: [],
        pageno: 1,
        cat_title:"",
        submitted: false,
        totalRecords: 0,
        id: "",
                  
          
           
    name: "",           
    status: "",      
    temp_ward_id:"",     
           
    loading: false, 
      };
    },
    async created(){
        this.initFilters();

        await this.getWardList();

    },
    
    async mounted() {
      this.id = this.$route.params.id;
      this.lazyParams = {
        first: 0,
        rows: this.limit,
        sortField: null,
        sortOrder: null,
        filters: this.filters,
      };
      if (this.totalRecords == 0) {
        await this.get_count();
      }
      await this.get_list();
    },
    watch: {
      async $route() {
        this.products = [];
        this.columns = [];
        this.totalRecords = 0;
        this.id = this.$route.params.id;
        if (this.id) {
          await this.get_count();
          this.get_list();
        }
      },
    },
    methods: {
      
      save_status()
        {   this.status_id="";
            this.status_id=this.status_selected.name;
       
            if (!this.product.ward_no) 
            {        
              this.$toast.add({
                  severity: "error",
                  summary: "error",
                  detail: this.$t('wardno_empty_error'),
                  life: 3000,
              });
              return false;
            }

            if (!this.product.name) 
            {        
              this.$toast.add({
                  severity: "error",
                  summary: "error",
                  detail: this.$t('wardname_empty_error'),
                  life: 3000,
              });
              return false;
            }

          

            // if (!this.status_selected ) 
            // {       
            //   this.$toast.add({
            //       severity: "error",
            //       summary: "error",
            //       detail: "Please Select Status",
            //       life: 3000,
            //   });
            //   return false;
            // }

           

            if (this.product._id) 
            {
              var data = {                        
                    name: this.product.name,
                    _id:this.product._id,
                    user_id: localStorage.getItem("id"),
                  
                    status:this.product.status,
                    ward_no: this.product.ward_no,
                    user_id: localStorage.getItem("id"),
  
                    
            };
             
              
              
                this.isLoadingModel=true;
                var promise = apis.updateward(data);
                promise.then((response) => {
                    this.isLoadingModel=false;
                    this.productDialog=false;
                    this.$swal(response.data.message);
                    this.get_count();
                    this.get_list();
                });
        
            } 
          

           
        },
      
      delete_toggle(data) 
    { 
      console.log(data)
      this.temp_ward_id=data._id;
      this.$refs.del.toggle(event);
    },
        async add_cat()
    { 
     
       this.status_dropdownItem ="";
      this.ward_number ="";
      this.ward_name  ="";
       this.add_cat_model=true;
    },
    async save_cat() {


    
  this.submitted = true;

      if (!this.ward_number || !this.ward_name || !this.status_dropdownItem) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: this.$t('field_empty_error'),
          life: 3000,
        });
        return false;
      }

 
  

  let details = {
    ward_no: this.ward_number,
    name: this.ward_name,
    status: this.status_dropdownItem.value,
    user_id: localStorage.getItem("id"),
    
  };

  // if (this.id) {
  //   details["id"] = this.id;
  // }

  this.isLoadingModel = true;
  console.log("data",details);
  try {
    let response = await apis.wardAddEdit(details);
    this.isLoadingModel = false;

    this.$toast.add({
      severity: response.data.status ? "success" : "error",
      summary: response.data.status ? "Success" : "Error",
      detail: response.data.message,
      life: 3000,
    });

    if (response.data.status) {
      this.add_cat_model = false;
      this.get_list(); 
    }
  } catch (error) {
    this.isLoadingModel = false;
    this.$toast.add({
      severity: "error",
      summary: "Error",
      detail: "An error occurred while saving data",
      life: 3000,
    });
  }
},

go(path, ticketType, wardData) {
        
        localStorage.setItem("mis_status_group",ticketType);
        localStorage.setItem("wardNumber",wardData); 
        this.$router.push(path);
      },

showButtons(index) {
      //this.$set(this.showButton, index, true);
      this.showButton[index] = true;
    },
    hideButtons(index) {
      //this.$set(this.showButton, index, false);
      this.showButton[index] = false;
    },

    deleteWard() {
  // Check if the remark field is filled
  if (!this.delete_remark) {
    this.$toast.add({
      severity: "error",
      summary: "Error",
      detail: this.$t('remark_empty_error'),
      life: 3000,
    });
    return false;
  }

  // Prepare the payload
  const payload = {
    _id: this.temp_ward_id, // Ward ID to delete
    remark: this.delete_remark, // Additional remark (if needed for audit/logging)
    user_id: localStorage.getItem("id"), // User performing the action
  };

  // Show loading indicator
  this.isLoadingModel = true;

  // Call the API
  apis.deleteWard(payload)
    .then((response) => {
      this.isLoadingModel = false; // Hide loading indicator

      if (response.data.status) {
        // Success: Notify the user and refresh the list
        this.$swal(response.data.message);
        //this.getWardList(); // Refresh the ward list
        this.temp_ward_id = ""; // Reset temporary variables
        this.delete_remark = "";
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.get_list();
        this.get_count();
      } else {
        // Error: Show error toast
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: response.data.message,
          life: 3000,
        });
      }
    })
    .catch((error) => {
      this.isLoadingModel = false; // Hide loading indicator

      // Handle API errors
      this.$toast.add({
        severity: "error",
        summary: "API Error",
        detail: "An error occurred while deleting the ward.",
        life: 3000,
      });
      console.error(error);
    });
},

    Cancel_delete(){
      this.temp_product_id="";
      this.delete_remark="";
      this.$refs.del.hide();
    },
    async edit_cat(data)
    { 
      this.id=data._id;
      this.cat_title  = data.name;
      
      var color       = data.color;
      this.colorHEX   = color ? color.slice(1) : '6b719b';

      this.add_cat_model=true;
    },
         
     
        async Goback() {
        this.$router.push({ name: "ward" });
      },
        async getWardList() {
      var data = {
        //client_id: "65a4f82f6177e69880ece5d6",
       
        
        
      };
      // this.isLoadingModel = true;
      var promise = apis.getWardList(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ward_number_dropdown = response.data.data;
      });
    },
    
      async search(count) {
        if (count == 2) {
          this.wardname="";
          this.ward_no="";
          this.add_cat_model = false;
        
        }
        await this.get_list();
        await this.get_count();
      },
      async onPage(event) {
        this.lazyParams = event;
        this.page_no = event.page + 1;
        this.limit = event.rows;
        // console.log(event);
        //  await this.get_count();
        if (this.totalRecords > 0) {
          this.get_list();
        }
      },
      get_count: function () {
        var data = {

          user_id: localStorage.getItem("id"),
          
          count: true,
        
          search:this.status,
          _id:this.wardname._id,
          ward_no: this.ward_no,
          
        };
       
        
        this.isLoadingModel = true;
        var promise = apis.wardlist(data);
        promise.then((response) => {
          this.isLoadingModel = false;
          console.log(response);
     
          this.totalRecords = response.data.count; 
        //   alert(this.totalRecords);
          
        });
        console.log(data);
      },
      get_list: function () {
      
    
        var data = {
            limit: this.limit,        
            page_no: this.page_no,      
            ward_no: this.ward_no,
            _id:this.wardname._id,

               
            status: this.status, 
            count: false, 
            user_id: localStorage.getItem("id"),
        };
        this.isLoadingModel = true;
        var promise = apis.wardlist(data);
        promise.then((response) => {
          this.isLoadingModel = false;
          console.log(response);
          this.products = response.data.data;
          console.log("product data",this.products);
          // if (this.columns.length == 0) {
          //   if (this.products.length > 0) {
          //     this.columns = Object.keys(this.products[0]);
          //   }
          // }
        });
        //console.log(data);
      },
    //   openNew() {
    //     this.product = {};
    //     this.submitted = false;
    //     this.productDialog = true;
    //   },
      openNew() {
      this.product = {};
      this.submitted = false;
      // this.productDialog = true;
      this.$router.push({ name: "ward" });
    },
      // async editProduct(product) {
      //   this.file_attachment = "";
      //   this.product = { ...product };
      //   this.productDialog = true;
      // },
      async editProduct(product) {
        // this.file_attachment = "";
        this.product = { ...product };
        if (this.product.status) 
        {
            this.status_selected={ name: this.product.status, value:  this.product.status };
        }
       
        this.productDialog = true;
      },
      confirmDeleteProduct(product) {
        this.product = product;
        this.deleteProductDialog = true;
      },
    
     
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
    components: {  },
  };
  </script>
    <style scoped lang="scss">
  @import "../assets/demo/badges.scss";
  #custom_card {
  border-top: 3px solid #6b719b;
}
#custom_card_left {
 
  box-shadow:rgba(0, 0, 0, 0.1) 0px 8px 12px 0px !important;
}
.zoom {
  transform-origin: 0% 0% 1% 1%;
  transition: transform 1s, filter 1s ease-in-out;
}
/* The Transformation */
.zoom:hover {
  transform: scale(1.1);
}

.border-info {
    border-left: 5px solid  #0dcaf0 !important;
}

.per-page-and-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.per-page-dropdown {
  margin-right: 20px;
}
  </style>
  
    