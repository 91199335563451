<template>
  <div class="grid">
    <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
            >
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
              {{$t('employee')}}
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
            >
              <Button
                iconPos="right"
                :label="$t('add')"
                icon="pi pi-plus"
                v-tooltip.bottom="$t('add')"
                @click="add_agent"
                size="small"
                style="
                  box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                  border-color: #00b0b0 !important;
                  background-color: #00b0b0 !important;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card" id="custom_card">
        <div class="grid p-fluid">
          <div class="col-12 md:col-12">
            <div class="p-fluid formgrid grid">
              <!-- <div class="field col-12 md:col-10"></div> -->
              
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <InputText
                    format="text"
                    v-model="search"
                    @input="get_list(1)"
                  >
                  </InputText>
                  <label>{{ $t('search_employee') }}</label>
                </span>
              </div>

              <div class="field col-12 md:col-2" v-if="user_type=='Super Admin'">
                <span class="p-float-label">
                  <Dropdown
                    id="client_dropdown"
                    v-model="client_selected"
                    :options="client_dropdown"
                    :value="client_dropdown"
                    optionLabel="name"
                    filter
                    v-on:change="get_list(1)"
                  ></Dropdown>
                  <label for="Client">Client</label>
                </span>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div style="text-align: right; font-weight: bold ; font-size: 12px; margin-right: 35px;">
              <span>{{ $t('total_employee') }} : </span>{{ dataviewValue.length }}
          </div>
        </div> 
        <DataView
          :value="dataviewValue"
          :layout="layout"
          :paginator="true"
           :totalRecords="totalRecords"
          
          :rowsPerPageOptions="[8, 16, 24, totalRecords]"
          :rows="9"
          :sortOrder="sortOrder"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :sortField="sortField"
        >
          <template #grid="slotProps">

            <!-- {{slotProps}} -->
            <div class="grid grid-nogutter">
              <div
                class="col-12 md:col-4"

               

              >
              
                <div
                  style="height: 150px;width: 280px;"
                  class="card m-3 border-1 surface-border zoom"
                  id="custom_card_left"
                  @mouseover="showButtons(index)"
                  @mouseout="hideButtons(index)"
                  :style="{
                    'border-left': '5px solid ' + slotProps.data.color + ' !important',
                  }"
                >
                  <div class="grid">
                    <div class="col-12 md:col-4">
                      <div class="flex align-items-center">
                        <img
                          style="border-radius: 20%; width: 25px; height: 25px"
                          v-if="slotProps.data.profile_image"
                          :src="slotProps.data.profile_image"
                          :alt="slotProps.data.profile_image"
                        />
                    <!-- {{ this.color_data }} -->
                        <img
                          style="border-radius: 20%; width: 80px; height: 80px"
                          v-else
                          src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
                        />
                        <!-- <span v-else class="i-circle" :style="'background-color:'+(this.color_data[index]?this.color_data[index].code:'skyblue')">{{convert_name(slotProps.data.name)}}</span> -->
                        <span 
                          v-else 
                          class="i-circle" 
                          :style="{
                            backgroundColor: this.color_data[index] ? this.color_data[index].code : 'skyblue',
                            height: '25px', 
                            width: '25px'    
                          }">
                          {{convert_name(slotProps.data.name)}}
                        </span>

                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div>
                        <span
                          style="
                            color: #6b719b;
                            font-size: 15px;
                            
                            font-weight: 500;
                            cursor: pointer;
                          "
                          @click="view_agent(slotProps.data)"
                           >{{ slotProps.data.name }}</span
                        >&nbsp;
                      </div>
                      <br />
                      <div style="max-width: 250px; word-wrap: break-word">
                        <span
                          class="text-color-gray"
                          style="font-weight: 500 !important"
                          >{{ slotProps.data.email_id }}</span
                        >
                      </div>
                    </div>
                    <div class="col-12 md:col-2">
                      <!-- <i
                        @click="edit_agent(slotProps.data)"
                        v-show="showButton[index]"
                        class="pi pi-pencil"
                        style="
                          font-size: 1rem;
                         
                          color: #28a745 !important;
                          cursor: pointer;
                        "
                      ></i
                      >&nbsp; -->
                      <!-- <i
                        @click="confirmDeleteProduct(item)"
                        v-show="showButton[index]"
                        class="pi pi-trash"
                        style="
                          font-size: 1rem;
                          color: red !important;
                          cursor: pointer;
                        "
                      ></i> -->

                      <!-- ------------- -->
                      <i
                        @click="delete_toggle(slotProps.data)"
                        v-show="showButton[index]"
                        class="pi pi-trash"
                        style="
                          font-size: 1rem;
                          color: red !important;
                          cursor: pointer;
                        "
                      ></i>

                      <OverlayPanel ref="del" >
                          <div class="flex flex-column gap-3 w-25rem">
                            <h5>{{ $t('delete_employee') }} </h5>

                            <div class="col-12">
                              <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-12">
                                  <span class="p-float-label">
                                    <Textarea
                                        format="text"
                                        v-model="delete_remark"
                                      >
                                    </Textarea>
                                    <label>{{$t('remark')}}</label>
                                </span>
                                </div>

                                <!-- <div class="field col-12 md:col-4 " >
                                  <Button  label="Delete" @click="delete_product" class="p-button-primary btn_light_blue"/>
                                </div>
                                <div class="field col-12 md:col-4">
                                  <Button label="Cancel" @click="Cancel_delete"  class="p-button-danger btn_red"/>
                                </div> -->

                                <div class="field col-12 md:col-8" style="display: flex; justify-content: flex-end; gap: 10px; margin-left: 120px;">
                  <Button :label="$t('delete')" @click="delete_product" class="p-button-primary btn_light_blue" />
                  <Button :label="$t('cancel')" @click="Cancel_delete" class="p-button-danger btn_red" />
                </div>
           

                              </div>
                            </div>

                          </div>
                        </OverlayPanel>

                      <!-- ------------- -->
                    </div>
                    <div class="col-12 md:col-1">
                      <!-- <Badge :value="slotProps.data.task_assign?slotProps.data.task_assign:0"   v-tooltip="'Assigned Tickets'"></Badge> -->
                    </div>
                    <div class="col-12 md:col-9">
                      <!-- <span style="color: #6b719b">Assigned Tickets</span> -->
                    </div>
                    <div class="col-12 md:col-2">
                      <InputSwitch
                        v-model="slotProps.data.email_status"
                        v-on:change="verify_email(slotProps.data)"
                        
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </DataView>
        <!-- <div class="pageno">
                <Paginator
                  :rows="rows"
                  :totalRecords="totalRecords"
                  :rowsPerPageOptions="[6, 9, 24, totalRecords]"
                  @page="onPageChange"
                ></Paginator>
              </div> -->
      </div>
    </div>
  </div>
</template>
  <script>
import apis from "@/apis";
import axios from "axios";
import ColorService from "../service/ColorService";
export default {
  data() {
    return {
      rowsPerPage:8,
      ColorService:null,
        color_data:[],
      search: "",
      totalRecords: 0,
      perPageOptions: [8, 16, 24, 100],
      isLoadingModel: false,
      fullPage: false,
      dataviewValue: [],
      layout: "grid",
      sortKey: null,
      sortOrder: null,
      sortField: null,
      user_type: "",
      client_id: "",
      showButton: [],
      id: "",

      client_dropdown:[],
      client_selected:"",
      // clientid:"",
    };
  },
  productService: null,
  created() {
    this.ColorService = new ColorService();
  },
  async mounted() {
    this.ColorService.getColors().then((colors) => {
			this.color_data = colors;
		 
		  });

    this.user_type = localStorage.getItem("user_type");
    this.client_id = localStorage.getItem("client_id");
    await this.get_list();
    await this.getClientMaster();
  },
  methods: {
    async view_agent(item)
    { 
      await localStorage.setItem('AgentDetails','');
      await localStorage.setItem('AgentDetails',JSON.stringify(item));
      this.$router.push({ name: "agentdetails" });
    },
    convert_name(name)
      {
        if(name)
      {
        var x= this.ColorService.convertToSN(name);
        return x.toString();
      } 
      else
      {
        var x= "NA";
        return x.toString();
      }
      },
    async verify_email(data) {
      console.log(data);
      var status = "";
      if (data.email_status == true) {
        status = "Active";
      } else {
        status = "In Active";
      }
      let details = {
        user_id: data._id,
        status: status,
        type: "Status",
      };
      this.isLoadingModel = true;
      var promise = apis.updateUserStatus(details);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.$swal(response.data.message);
        this.get_list();
      });
    },
    async confirmDeleteProduct(product) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to Delete Employee?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          var payload = {
            "user_id": product._id,
            "status":"Deleted",
            "type":"Status"
          };
          this.isLoadingModel = true;
          var promise = apis.updateUserStatus(payload);
          promise.then((response) => {
            this.isLoadingModel = false;
            if (response.data.status == true) {
              this.$swal(response.data.message);
              this.get_list();
              window.scrollTo({ top: 0, behavior: "smooth" });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "ERROR!!!",
                detail: response.data.message,
                life: 3000,
              });
            }
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Cancel",
            detail: "You have Cancelled",
            life: 3000,
          });
        },
      });
    },

    delete_toggle(data) 
    { 
      this.temp_product_id=data._id;
      this.$refs.del.toggle(event);
    },

    delete_product(){
      if (this.delete_remark==null || this.delete_remark=="" || this.delete_remark==undefined) 
        {
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: this.$t('remark_empty_error'),
              life: 3000,
          });
          return false;
        }

        else
        {
          var payload = {
            "user_id": this.temp_product_id,
            "status":"Deleted",
            "type":"Status",
            "remark":this.delete_remark
          };

          this.isLoadingModel = true;
          var promise = apis.updateUserStatus(payload);
          promise.then((response) => {

            this.isLoadingModel = false;
            if (response.data.status == true) {
              this.$swal(response.data.message);
              this.get_list();
              this.temp_product_id="";
              this.delete_remark="";
              window.scrollTo({ top: 0, behavior: "smooth" });
            } 
            
            else {
              this.$toast.add({
                severity: "error",
                summary: "ERROR!!!",
                detail: response.data.message,
                life: 3000,
              });
            }
          });
        }
    },

    Cancel_delete(){
      this.temp_product_id="";
      this.delete_remark="";
      this.$refs.del.hide();
    },
    async add_agent() {
    
        await localStorage.setItem('user_type_flag',"Employee");
        this.$router.push({name:"user_add"});
    },
    async edit_agent(data) {
        // await localStorage.setItem("AgentDetails", "");
        // await localStorage.setItem("AgentDetails",JSON.stringify(data));
        // this.$router.push({ name: "user_add", });
        data.client_id='65a4f82f6177e69880ece5d6';
        await localStorage.setItem('user_type_flag',"Employee");
        await localStorage.setItem('saveuserdata',JSON.stringify(data));
        this.$router.push({name:"user_edit"});
    },
    

    async getClientMaster() {
      var data = {
          limit: 10000,
          page_no: 1,
          count: false,
          user_id: localStorage.getItem("id"),
      };
     
      var promise = apis.getclientist(data);
      promise.then((response) => {
       this.client_dropdown = response.data.data;
      });
    },

    get_count: function () {
      var data = {
        count: true,
 
        client_id: this.client_id,
        user_id: localStorage.getItem("id"),
       
      };
      this.loading = true;
      var promise = apis.getFaqMaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data.data;
        //alert(this.totalRecords);
      });
      console.log(data);
    },

    async get_list() {
      this.client_id = "";
      this.client_id = this.client_selected._id;
     
      if (this.client_id=='' || this.client_id==undefined) 
      {
        this.client_id=localStorage.getItem("client_id");
      }

      var data = {
        // client_id: "65a4f82f6177e69880ece5d6",
        client_id: this.client_id,
        search: this.search,
        user_id: localStorage.getItem("id"),
      };
      this.isLoadingModel = true;
      var promise = apis.adminAgentListMaster(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.dataviewValue = response.data.data;
        for (let index = 0; index < this.dataviewValue.length; index++) {
          if (this.dataviewValue[index].status == "Active") {
            this.dataviewValue[index].email_status = true;
          } else {
            this.dataviewValue[index].email_status = false;
          }
        }
      });
    },
    showButtons(index) {
      //this.$set(this.showButton, index, true);
      this.showButton[index] = true;
    },
    hideButtons(index) {
      //this.$set(this.showButton, index, false);
      this.showButton[index] = false;
    },
  },
};
</script>
  <style scoped >
@import "../assets/demo/badges.scss";
#custom_card {
  border-top: 3px solid #6b719b;
}
#custom_card_left {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px 0px !important;
}
.zoom {
  transform-origin: 0% 0% 1% 1%;
  transition: transform 1s, filter 1s ease-in-out;
}
/* The Transformation */
.zoom:hover {
  transform: scale(1.1);
}
.border-info {
  border-left: 5px solid #0dcaf0 !important;
}
.text-color-gray {
  color: gray !important;
}
</style>
  





