<template>
  <div style="background-color: #f4f6f9">
    <div class="px-4 lg:px-8">
      <div class="grid pb-2 md:pb-8">
        <div
          class="flex justify-content-end col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0"
          style="border-radius: 8px"
        >
          <img
            src="images/eTicket_logo_new.png"
            class="w-8"
            alt="mockup mobile"
          />
        </div>
        <div
          class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-center lg:text-right align-items-center text-center"
        >
          <h3>{{$t('how_can')}}</h3>
          <h1 class="tittle1">{{$t('wel-come')}}</h1>
          <h1 class="tittle1"> {{$t('desk')}}</h1>
          <!-- <InputGroup>
            <InputText placeholder="Search Ticket ..." />
            <Button class="btn_yellow" icon="pi pi-search" severity="warning" @click="search"/>
          </InputGroup> -->
        </div>
      </div>
    </div>
    <!-- <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="features">
      <div class="grid justify-content-center">
        <div class="col-12 text-center mt-8 mb-4">
          <h2 style="color: #6b719b; font-size: 30px; font-weight: 800">
            Categories
          </h2>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(253, 228, 165, 0.2),
                  rgba(187, 199, 205, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(253, 228, 165, 0.2),
                  rgba(187, 199, 205, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  250
                </p>
              </div>
              <h5 class="mb-2 text-900">Billing Issue</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(145, 226, 237, 0.2),
                  rgba(251, 199, 145, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(253, 228, 165, 0.2),
                  rgba(172, 180, 223, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-cyan-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  200
                </p>
              </div>
              <h5 class="mb-2 text-900">Car Request</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(145, 226, 237, 0.2),
                  rgba(172, 180, 223, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(172, 180, 223, 0.2),
                  rgba(246, 158, 188, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-indigo-200"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
              
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  150
                </p>
              </div>
              <h5 class="mb-2 text-900">Checker</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(187, 199, 205, 0.2),
                  rgba(251, 199, 145, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(253, 228, 165, 0.2),
                  rgba(145, 210, 204, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-bluegray-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
               
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  350
                </p>
              </div>
              <h5 class="mb-2 text-900">Guest Issue</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(187, 199, 205, 0.2),
                  rgba(246, 158, 188, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(145, 226, 237, 0.2),
                  rgba(160, 210, 250, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-orange-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  450
                </p>
              </div>
              <h5 class="mb-2 text-900">Clean Code</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(251, 199, 145, 0.2),
                  rgba(246, 158, 188, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(172, 180, 223, 0.2),
                  rgba(212, 162, 221, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-pink-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  550
                </p>
              </div>
              <h5 class="mb-2 text-900">Dark Mode</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(145, 210, 204, 0.2),
                  rgba(160, 210, 250, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(187, 199, 205, 0.2),
                  rgba(145, 210, 204, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-teal-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  150
                </p>
              </div>
              <h5 class="mb-2 text-900">Ready to Use</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(145, 210, 204, 0.2),
                  rgba(212, 162, 221, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(251, 199, 145, 0.2),
                  rgba(160, 210, 250, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-blue-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
               
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  350
                </p>
              </div>
              <h5 class="mb-2 text-900">Modern Practices</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg-4 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(160, 210, 250, 0.2),
                  rgba(212, 162, 221, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(246, 158, 188, 0.2),
                  rgba(212, 162, 221, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-purple-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
               
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  450
                </p>
              </div>
              <h5 class="mb-2 text-900">Privacy</h5>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="features">
      <div class="grid justify-content-center">
        <div class="col-12 text-center mt-8 mb-4">
          <h2 style="color: #6b719b; font-size: 30px; font-weight: 800">
            Public Tickets
          </h2>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(253, 228, 165, 0.2),
                  rgba(187, 199, 205, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(253, 228, 165, 0.2),
                  rgba(187, 199, 205, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  250
                </p>
              </div>
              <h5 class="mb-2 text-900">Billing Issue</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(145, 226, 237, 0.2),
                  rgba(251, 199, 145, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(253, 228, 165, 0.2),
                  rgba(172, 180, 223, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-cyan-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  200
                </p>
              </div>
              <h5 class="mb-2 text-900">Car Request</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(145, 226, 237, 0.2),
                  rgba(172, 180, 223, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(172, 180, 223, 0.2),
                  rgba(246, 158, 188, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-indigo-200"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  150
                </p>
              </div>
              <h5 class="mb-2 text-900">Checker</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(187, 199, 205, 0.2),
                  rgba(251, 199, 145, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(253, 228, 165, 0.2),
                  rgba(145, 210, 204, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-bluegray-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  350
                </p>
              </div>
              <h5 class="mb-2 text-900">Guest Issue</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(187, 199, 205, 0.2),
                  rgba(246, 158, 188, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(145, 226, 237, 0.2),
                  rgba(160, 210, 250, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-orange-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  450
                </p>
              </div>
              <h5 class="mb-2 text-900">Clean Code</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(251, 199, 145, 0.2),
                  rgba(246, 158, 188, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(172, 180, 223, 0.2),
                  rgba(212, 162, 221, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-pink-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  550
                </p>
              </div>
              <h5 class="mb-2 text-900">Dark Mode</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(145, 210, 204, 0.2),
                  rgba(160, 210, 250, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(187, 199, 205, 0.2),
                  rgba(145, 210, 204, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-teal-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  150
                </p>
              </div>
              <h5 class="mb-2 text-900">Ready to Use</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(145, 210, 204, 0.2),
                  rgba(212, 162, 221, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(251, 199, 145, 0.2),
                  rgba(160, 210, 250, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-blue-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
               
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  350
                </p>
              </div>
              <h5 class="mb-2 text-900">Modern Practices</h5>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-12 lg:col-4 p-0 lg-4 mt-4 lg:mt-0">
          <div
            style="
              height: 120px;
              padding: 2px;
              border-radius: 10px;
              background: linear-gradient(
                  90deg,
                  rgba(160, 210, 250, 0.2),
                  rgba(212, 162, 221, 0.2)
                ),
                linear-gradient(
                  180deg,
                  rgba(246, 158, 188, 0.2),
                  rgba(212, 162, 221, 0.2)
                );
            "
          >
            <div class="p-3 surface-card h-full" style="border-radius: 8px">
              <div
                class="flex align-items-center justify-content-center bg-purple-200 mb-3"
                style="width: 5.5rem; height: 3.5rem; border-radius: 10px"
              >
                
                <p style="color: #6b719b; font-size: 30px; font-weight: 800">
                  450
                </p>
              </div>
              <h5 class="mb-2 text-900">Privacy</h5>
            </div>
          </div>
        </div>
      </div>
    </div> -->


    

    <ScrollTop />
  </div>
</template>
<script>
export default {
  name: "EticketHomeComponent",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    
  },
};
</script>
<style scoped>
.tittle1 {
  color: #6b719b;
  font-size: 60px;
  font-weight: 800;
  letter-spacing: 1pt;
  line-height: 1;
}
@media screen and (min-width: 992px) {
  .lg\:align-items-center {
    align-items: start !important;
  }
}
</style>