<template>
  <div class="card">
    <tabs v-model="selectedTab">
      <tab class="tab" v-for="(tab, i) in tabs" :key="`t${i}`" :val="tab" :label="tab" :indicator="true" style="font-size: 13px;" :class="{ active: i === selectedTab , inactive: i != selectedTab }">
        {{ tab }}
            </tab>
    </tabs>
    <tab-panels v-model="selectedTab" :animate="true">
      <tab-panel style="height:1000px">
        <!-- {{selected_tab}} -->
        <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
        <!-- <div v-if="selectedTab=='Dashboard'" style="margin-top:20px;height:800px">
        <superset_dashboard></superset_dashboard>
      </div> -->
        <div v-if="selectedTab == 'Dashboard'" style="margin-top:20px;height:800px">
          <DashboardComponent></DashboardComponent>
        </div>
        <div v-if="selectedTab == 'Live Ride Tracking Dashboard'" style="margin-top:20px;height:200px">
          <div class="col-12 lg:col-6 lg:col-12" style="padding:0!important;width:100%;height:700px" v-if="home == true">
            <InputText type="text" placeholder="Mobile Number" v-model="search_key" v-on:keyup.enter="search_people">
            </InputText>
            &nbsp;
            <InputText type="text" placeholder="name" v-model="name" v-on:keyup.enter="search_people">
            </InputText>&nbsp;
            <InputText type="text" placeholder="City" v-model="city" v-on:keyup.enter="search_people">
            </InputText>&nbsp;
            <InputText type="text" placeholder="State" v-model="state" v-on:keyup.enter="search_people">
            </InputText>&nbsp;
            <InputText type="text" placeholder="Pincode" v-model="pincode" v-on:keyup.enter="search_people">
            </InputText>&nbsp;
            <InputText type="text" placeholder="Vehicle Number" v-model="vehicle_number" v-on:keyup.enter="search_people">
            </InputText>&nbsp;&nbsp;
            <!-- <InputText type="text" class="form-control mt-2"  placeholder="Route" style="align:right;height:30px" v-model="router" v-on:keyup.enter="search_people"></InputText>&nbsp; -->
            <Dropdown id="status" filter style="width:180px" v-model="routeitem" :options="routeitems"
              :value="routeitems.value" optionLabel="name" placeholder="Select Route" required="true"
              @change="search_people"></Dropdown>&nbsp;
            <Dropdown v-if="user_key && (user_key == 'Admin' || user_key == 'admin')" style="width:180px" id="state"
              v-model="client_id_search" filter placeholder="Select Client" :options="client_id_dropdown"
              optionLabel="name" :optionValue="value" @change="search_people"></Dropdown>
            &nbsp;
            <Button icon="pi pi-search" @click="search_people" class="p-button-rounded p-button-primary mt-2" />&nbsp;
            <Button icon="pi pi-refresh" @click="reset_search_people" class="p-button-rounded p-button-primary mt-2" />
            <div style="text-align:right;font-weight:bold"><span>Total Rides:</span>{{ travellist_filter.length }}</div>
            <hr>
            <!-- {{travellist}} -->
            <!-- <GoogleMap  api-key="AIzaSyA62nfiBf_7XngEJxzZxltpRMbJjd2f7Fg" style="width: 100%; height: 85%" :center="center"  :zoom="5"> -->
            <GoogleMap api-key="AIzaSyC-8ib8PMibUDDYoj5kzRzKwcusvw3Gztc" style="width: 100%; height: 85%" :center="center"
              :zoom="5">
              <MarkerCluster>
                <Marker v-for="(location, i) in travellist_filter" :options="{ position: location }" :key="i">
                  <InfoWindow>
                    <div id="contet" style="width:450px">
                      <div id="siteNotice"></div>
                      <!-- <h1 id="firstHeading" class="firstHeading"></h1> -->
                      <div id="bodyContent" class="grid" style="font-size:11px;width:600px">
                        <!-- {{location.profile_image}} -->
                        <div class="col-3 lg:col-3 lg:col-3">
                          <img :src="location.profile_image" style="width:100px;border-radius:70%" />
                        </div>
                        <div class="col-9 lg:col-9 lg:col-9">
                          <b>Commuter Name :</b> {{ location.commuter_name }}
                          <br>
                          <b>Mobile Number :</b> {{ location.mobile_number }}
                          <br>
                          <b>Latitude :</b> {{ location.lat }} <b>Longitude :</b> {{ location.lng }}
                          <br>
                          <b>Place Details :</b><br> <span>{{ location.place_add }}</span><br>
                          <b>Driver Name :</b> {{ location.driver_name }}
                          <br><b>Driver Mobile Number :</b> {{ location.driver_mobile_number }}
                        </div>
                        <p>
                        </p>
                        <p style="width:300px">
                        </p>
                        <p>
                        </p>
                        <p>
                        </p>
                        <Button label="View details" @click="view_details(location)"
                          class="p-button-info p-button-sm	 mr-2 mb-2" />
                      </div>
                    </div>
                  </InfoWindow>
                </Marker>
              </MarkerCluster>
            </GoogleMap>
          </div>
          <div class="col-12 lg:col-6 xl:col-8" v-if="home == false" style="padding:0!important;width:100%;height:700px">
            <Button style="text-align-right" label="Back" @click="back_map()"
              class="p-button-info p-button-sm	 mr-2 mb-2" />
            <!-- {{center1}} -->
            <!-- <GoogleMap   ref="mapRef" id='maph' api-key="AIzaSyA62nfiBf_7XngEJxzZxltpRMbJjd2f7Fg" style="width: 100%; height: 95%;" :center="center1"  :zoom="15">
				    <Marker :options="{ position: centerstart }"  >
					</Marker>
            <Marker :options="{ position: center1 }"  >
					</Marker>
          <Polyline v-if="polyline.length>0"  :options="polypath" />
        </GoogleMap> -->
            <!-- {{center1.lat}} -->
            <GoogleMapDashboard :center1="center1" :lat="center1.lat" :lng="center1.lng" :centerstart="centerstart"
              :polyline="polyline" :polypath="polypath" :stops="mapstops" />
          </div>
        </div>
      </tab-panel>
    </tab-panels>
  </div>
  
</template>
<script>
import EventBus from '@/AppEventBus';
import ProductService from '../service/ProductService';
import apis from "@/apis";
import axios from "axios";
import { Buffer } from 'buffer'
import { embedDashboard } from "@superset-ui/embedded-sdk";
import GoogleMapComponent from '../components/GoogleMap'
import superset_dashboard from '../components/superset_dashboard'
import DashboardComponent from '../components/DashboardComponent'
import GoogleMapDashboard from '../components/GoogleMapDashboard'
import Map from "mapmyindia-react";
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';
import SocketioService from "../service/socketio.service.js";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { GoogleMap, Marker, MarkerCluster, InfoWindow, GMapMarker, Polyline } from "vue3-google-map";
import socketioService from '../service/socketio.service.js';
// import Superset_dashboard from './superset_dashboard.vue';
// embedDashboard({
//   id: "f151aeed-6800-42fb-befc-9a4dd719ff0b", // given by the Superset embedding UI
//   supersetDomain: "http://192.168.1.22:8088/",
//   mountPoint: document.getElementById("my-superset-container"), // any html element that can contain an iframe
//   fetchGuestToken: () => fetchGuestTokenFromBackend(),
//   dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
//       hideTitle: true,
//       filters: {
//           expanded: true,
//       }
//   },
// });
export default {
  data() {
    return {
      mapstops: [],
      user_key: "",
      client_id_dropdown: [],
      client_id_search: '',
      routeitem: '',
      routeitems: [],
      isLoadingModel: false,
      pieOptions: null,
      tabs: ['Dashboard', 'Live Ride Tracking Dashboard'],
      selectedTab: "Dashboard",
      pieData: {},
      pieData1: {},
      center: { lat: 19.0626704, lng: 73.005094 },
      center1: {},
      centerstart: {},
      polyline: [
      ],
      polyline1: [
      ],
      polypath: {
        // path: this.polyline,
        // geodesic: true,
        // strokeColor: '#FF0000',
        // strokeOpacity: 1.0,
        // strokeWeight: 2,
      },
      search_key: "",
      home: true,
      random: 0,
      token: '',
      get_date: "",
      get_date_2: "",
      locations1: [],
      locations: [
        { lat: 19.0626704, lng: 73.005094, name: "Prathamesh" },
        { lat: 28.38, lng: 77.12, name: "Sanjay" }
      ],
      markers: [
        {
          position: [18.5314, 73.845],
          draggable: true,
          title: "Marker title"
        }]
      ,
      messages: [],
      inputMessageText: "",
      form: {
        roomName: "63a939f2b319a2f01eb75798",
        userName: "639c6caae0aa678e9f6de543",
      },
      products: null,
      todayslectures: [],
      dashcount: {
        "riders": 0,
        "drivers": 0,
        "driverowner": 0,
        "vehicles": 0,
        "owners": 0,
        "admin": 0,
        "travels": 0,
        "schools": 0,
        "alerts": 0,
        "today_alerts": 0,
        "today_travels": 0,
        "school_buses": 0,
      },
      // center: [37,7749, -122,4194],
      lineData: {
        labels: ['0', '0 to 20', '20 to 40', '40 to 60', '60 to 80', '80 to 100'],
        datasets: [
          {
            label: 'Network',
            data: [],
            backgroundColor: '#00bb7e',
          },
          // {
          // 	label: 'Sales',
          // 	data: [],
          // 	fill: false,
          // 	backgroundColor: '#00bb7e',
          // 	borderColor: '#00bb7e',
          // 	tension: 0.4
          // }
        ]
      },
      travellist: [],
      travellist_filter: [],
      lastNumber: 1,
      barData: {
        labels: [],
        height: "150px",
        datasets: [
          {
            label: 'Total Rides',
            backgroundColor: '#2f4860',
            data: []
          }
        ]
      },
      barData1: {
        labels: [],
        datasets: [
          {
            label: 'No Of Lectures by Teacher',
            backgroundColor: '#2f4860',
            data: []
          }
        ]
      },
      barOptions: null,
      barOptions1: null,
      items: [
        { label: 'Add New', icon: 'pi pi-fw pi-plus' },
        { label: 'Remove', icon: 'pi pi-fw pi-minus' }
      ],
      lineOptions: null,
    }
  },
  productService: null,
  themeChangeListener: null,
  async mounted() {
    // this.load_map();
    // this.productService.getProductsSmall().then(data => this.products = data);
    this.themeChangeListener = (event) => {
      if (event.dark)
        this.applyDarkTheme();
      else
        this.applyLightTheme();
    };
    EventBus.on('change-theme', this.themeChangeListener);
    if (this.isDarkTheme()) {
      this.applyDarkTheme();
    }
    else {
      this.applyLightTheme();
    }
    // this.get_date=new Date();
    // this.datausagereport();
    // this.get_date_2=new Date();
    //  await this.setupLeafletMap();
    await this.gettodaysTravelList();
    // this.networkconnectivityreport();
    // this.todayslecturereport();
    // this.teacherlecturereportadmin();
    //   this.timer = setInterval(() => {
    // 	 this.getrandomLatlong( this.getRandNumber())
    // }, 1000)
    // this.pieOptions = {
    // 		plugins: {
    // 			legend: {
    // 				labels: {
    // 					color: '#495057'
    // 				}
    // 			}
    // 		}
    // 	};
    // alert(this.dashcount.drivers)
  },
  beforeUnmount() {
    EventBus.off('change-theme', this.themeChangeListener);
  },
  async created() {
    window.Buffer = window.Buffer || Buffer
    // alert(this.token)
    //         supersetEmbeddedSdk.embedDashboard({
    //   id: "f151aeed-6800-42fb-befc-9a4dd719ff0b", // given by the Superset embedding UI
    // supersetDomain: "http://192.168.1.22:8088/",
    // mountPoint: document.getElementById("my-superset-container"), // any html element that can contain an iframe
    // fetchGuestToken: () =>  this.token,
    // dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
    //      hideTitle: true,
    //     // filters: {
    //     //     expanded: true,
    //     // }
    //   }});
    // this.load_map();
    this.productService = new ProductService();
    this.user_key = localStorage.getItem("user_key");
    await this.dashboardcount();
    await this.dashboardRideData();
    await this.route_list();
    await this.get_client_list();
    // await this.superset_api();
    // this.load_map_superset();
    // var token=await this.superset_api();
    // alert(this.token);
    // this.load_map();
  },
  watch: {
    selectedTab() {
      if (this.selectedTab == "Dashboard") {
        // this.load_map_superset();
      }
    },
    polyline() {
      alert('ok');
      this.home = true;
      this.home = false;
    }
  },
  methods: {
    getaltimage() {
      return "https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o=";
    },
    reset_search_people() {
      this.search_key = '';
      this.name = '';
      this.city = '';
      this.state = '';
      this.pincode = '';
      this.vehicle_number = '';
      this.routeitem = '';
      this.client_id_search = '';
    },
    search_people() {
      if (this.search_key || this.name || this.city || this.state || this.pincode || this.vehicle_number || this.routeitem.value || this.client_id_search.value) {
        // alert(1);
        this.gettodaysTravelList();
      }
    },
    uniqueAssociativeArray(array) {
      let uniqueArray = {};
      for (let i = 0; i < array.length; i++) {
        let currentElement = array[i];
        if (!uniqueArray[currentElement]) {
          uniqueArray[currentElement] = true;
        }
      }
      return Object.keys(uniqueArray);
    },
    filterAssociativeArray(arr, key, values) {
      var result = arr.filter(function (a) {
        return values.indexOf(a[key]) !== -1;
      });
      return result;
    },
    gettodaysTravelList() {
      this.isLoadingModel = true;
      var data = {
        "route_id": this.routeitem.value,
        //"search_client":this.client_id_search.value,
        "mobile_number": this.search_key,
        "city": this.city,
        "pincode": this.pincode,
        "state": this.state,
        "vehicle_number": this.vehicle_number,
        "name": this.name,
        "client_id": this.client_id_search.value ? this.client_id_search.value : localStorage.getItem("client_id")
      }
      var promise = apis.todaysTravelListDashboard(data);
      promise
        .then(response => {
          // alert(1);
          this.isLoadingModel = false;
          this.travellist = response.data.data;
          for (var i = 0; i < this.travellist.length; i++) {
            this.travellist[i].lat = parseFloat(this.travellist[i].lat);
            this.travellist[i].lng = parseFloat(this.travellist[i].lng);
          }
          this.travellist_filter = this.travellist;
        }).catch((error) => {
          //console.log(error);
          this.isLoadingModel = false;
          // this.$swal.fire(error.response.data);
        })
        ;
    },
    getrandomLatlong(index) {
      var array = [
        {
          "name": "Mumbai",
          "lat": 18.987807,
          "lng": 72.836447
        },
        {
          "name": "Delhi",
          "lat": 28.651952,
          "lng": 77.231495
        },
        {
          "name": "Kolkata",
          "lat": 22.562627,
          "lng": 88.363044
        },
        {
          "name": "Chennai",
          "lat": 13.084622,
          "lng": 80.248357
        },
        {
          "name": "Bengalūru",
          "lat": 12.977063,
          "lng": 77.587106
        },
        {
          "name": "Hyderabad",
          "lat": 17.384052,
          "lng": 78.456355
        },
        {
          "name": "Ahmadābād",
          "lat": 23.025793,
          "lng": 72.587265
        },
        {
          "name": "Hāora",
          "lat": 22.576882,
          "lng": 88.318566
        },
        {
          "name": "Pune",
          "lat": 18.513271,
          "lng": 73.849852
        },
        {
          "name": "Sūrat",
          "lat": 21.195944,
          "lng": 72.830232
        },
        {
          "name": "Mardānpur",
          "lat": 26.430066,
          "lng": 80.267176
        },
        {
          "name": "Rāmpura",
          "lat": 26.884682,
          "lng": 75.789336
        },
        {
          "name": "Lucknow",
          "lat": 26.839281,
          "lng": 80.923133
        },
        {
          "name": "Nāra",
          "lat": 21.203096,
          "lng": 79.089284
        },
        {
          "name": "Patna",
          "lat": 25.615379,
          "lng": 85.101027
        },
        {
          "name": "Indore",
          "lat": 22.717736,
          "lng": 75.85859
        },
        {
          "name": "Vadodara",
          "lat": 22.299405,
          "lng": 73.208119
        },
        {
          "name": "Bhopal",
          "lat": 23.254688,
          "lng": 77.402892
        },
        {
          "name": "Coimbatore",
          "lat": 11.005547,
          "lng": 76.966122
        },
        {
          "name": "Ludhiāna",
          "lat": 30.912042,
          "lng": 75.853789
        },
        {
          "name": "Āgra",
          "lat": 27.187935,
          "lng": 78.003944
        },
        {
          "name": "Kalyān",
          "lat": 19.243703,
          "lng": 73.135537
        },
        {
          "name": "Vishākhapatnam",
          "lat": 17.704052,
          "lng": 83.297663
        },
        {
          "name": "Kochi",
          "lat": 9.947743,
          "lng": 76.253802
        },
        {
          "name": "Nāsik",
          "lat": 19.999963,
          "lng": 73.776887
        },
        {
          "name": "Meerut",
          "lat": 28.980018,
          "lng": 77.706356
        },
        {
          "name": "Farīdābād",
          "lat": 28.411236,
          "lng": 77.313162
        },
        {
          "name": "Vārānasi",
          "lat": 25.31774,
          "lng": 83.005811
        },
        {
          "name": "Ghāziābād",
          "lat": 28.665353,
          "lng": 77.439148
        },
        {
          "name": "Āsansol",
          "lat": 23.683333,
          "lng": 86.983333
        },
        {
          "name": "Jamshedpur",
          "lat": 22.802776,
          "lng": 86.185448
        },
        {
          "name": "Madurai",
          "lat": 9.917347,
          "lng": 78.119622
        },
        {
          "name": "Jabalpur",
          "lat": 23.174495,
          "lng": 79.935903
        },
        {
          "name": "Rājkot",
          "lat": 22.291606,
          "lng": 70.793217
        },
        {
          "name": "Dhanbād",
          "lat": 23.801988,
          "lng": 86.443244
        },
        {
          "name": "Amritsar",
          "lat": 31.622337,
          "lng": 74.875335
        },
        {
          "name": "Warangal",
          "lat": 17.978423,
          "lng": 79.600209
        },
        {
          "name": "Allahābād",
          "lat": 25.44478,
          "lng": 81.843217
        },
        {
          "name": "Srīnagar",
          "lat": 34.085652,
          "lng": 74.805553
        },
        {
          "name": "Aurangābād",
          "lat": 19.880943,
          "lng": 75.346739
        },
        {
          "name": "Bhilai",
          "lat": 21.209188,
          "lng": 81.428497
        },
        {
          "name": "Solāpur",
          "lat": 17.671523,
          "lng": 75.910437
        },
        {
          "name": "Ranchi",
          "lat": 23.347768,
          "lng": 85.338564
        },
        {
          "name": "Jodhpur",
          "lat": 26.26841,
          "lng": 73.005943
        },
        {
          "name": "Guwāhāti",
          "lat": 26.176076,
          "lng": 91.762932
        },
        {
          "name": "Chandigarh",
          "lat": 30.736292,
          "lng": 76.788398
        },
        {
          "name": "Gwalior",
          "lat": 26.229825,
          "lng": 78.173369
        },
        {
          "name": "Thiruvananthapuram",
          "lat": 8.485498,
          "lng": 76.949238
        },
        {
          "name": "Tiruchchirāppalli",
          "lat": 10.815499,
          "lng": 78.696513
        },
        {
          "name": "Hubli",
          "lat": 15.349955,
          "lng": 75.138619
        },
        {
          "name": "Mysore",
          "lat": 12.292664,
          "lng": 76.638543
        },
        {
          "name": "Raipur",
          "lat": 21.233333,
          "lng": 81.633333
        },
        {
          "name": "Salem",
          "lat": 11.651165,
          "lng": 78.158672
        },
        {
          "name": "Bhubaneshwar",
          "lat": 20.272411,
          "lng": 85.833853
        },
        {
          "name": "Kota",
          "lat": 25.182544,
          "lng": 75.839065
        },
        {
          "name": "Jhānsi",
          "lat": 25.458872,
          "lng": 78.579943
        },
        {
          "name": "Bareilly",
          "lat": 28.347023,
          "lng": 79.421934
        },
        {
          "name": "Alīgarh",
          "lat": 27.881453,
          "lng": 78.07464
        },
        {
          "name": "Bhiwandi",
          "lat": 19.300229,
          "lng": 73.058813
        },
        {
          "name": "Jammu",
          "lat": 32.735686,
          "lng": 74.869112
        },
        {
          "name": "Morādābād",
          "lat": 28.838931,
          "lng": 78.776838
        },
        {
          "name": "Mangalore",
          "lat": 12.865371,
          "lng": 74.842432
        },
        {
          "name": "Kolhāpur",
          "lat": 16.695633,
          "lng": 74.231669
        },
        {
          "name": "Amrāvati",
          "lat": 20.933272,
          "lng": 77.75152
        },
        {
          "name": "Dehra Dūn",
          "lat": 30.324427,
          "lng": 78.033922
        },
        {
          "name": "Mālegaon Camp",
          "lat": 20.569974,
          "lng": 74.515415
        },
        {
          "name": "Nellore",
          "lat": 14.449918,
          "lng": 79.986967
        },
        {
          "name": "Gopālpur",
          "lat": 26.735389,
          "lng": 83.38064
        },
        {
          "name": "Shimoga",
          "lat": 13.932424,
          "lng": 75.572555
        },
        {
          "name": "Tiruppūr",
          "lat": 11.104096,
          "lng": 77.346402
        },
        {
          "name": "Raurkela",
          "lat": 22.224964,
          "lng": 84.864143
        },
        {
          "name": "Nānded",
          "lat": 19.160227,
          "lng": 77.314971
        },
        {
          "name": "Belgaum",
          "lat": 15.862643,
          "lng": 74.508534
        },
        {
          "name": "Sāngli",
          "lat": 16.856777,
          "lng": 74.569196
        },
        {
          "name": "Chānda",
          "lat": 19.950758,
          "lng": 79.295229
        },
        {
          "name": "Ajmer",
          "lat": 26.452103,
          "lng": 74.638667
        },
        {
          "name": "Cuttack",
          "lat": 20.522922,
          "lng": 85.78813
        },
        {
          "name": "Bīkaner",
          "lat": 28.017623,
          "lng": 73.314955
        },
        {
          "name": "Bhāvnagar",
          "lat": 21.774455,
          "lng": 72.152496
        },
        {
          "name": "Hisar",
          "lat": 29.153938,
          "lng": 75.722944
        },
        {
          "name": "Bilāspur",
          "lat": 22.080046,
          "lng": 82.155431
        },
        {
          "name": "Tirunelveli",
          "lat": 8.725181,
          "lng": 77.684519
        },
        {
          "name": "Guntūr",
          "lat": 16.299737,
          "lng": 80.457293
        },
        {
          "name": "Shiliguri",
          "lat": 26.710035,
          "lng": 88.428512
        },
        {
          "name": "Ujjain",
          "lat": 23.182387,
          "lng": 75.776433
        },
        {
          "name": "Davangere",
          "lat": 14.469237,
          "lng": 75.92375
        },
        {
          "name": "Akola",
          "lat": 20.709569,
          "lng": 76.998103
        },
        {
          "name": "Sahāranpur",
          "lat": 29.967896,
          "lng": 77.545221
        },
        {
          "name": "Gulbarga",
          "lat": 17.335827,
          "lng": 76.83757
        },
        {
          "name": "Bhātpāra",
          "lat": 22.866431,
          "lng": 88.401129
        },
        {
          "name": "Dhūlia",
          "lat": 20.901299,
          "lng": 74.777373
        },
        {
          "name": "Udaipur",
          "lat": 24.57951,
          "lng": 73.690508
        },
        {
          "name": "Bellary",
          "lat": 15.142049,
          "lng": 76.92398
        },
        {
          "name": "Tuticorin",
          "lat": 8.805038,
          "lng": 78.151884
        },
        {
          "name": "Kurnool",
          "lat": 15.828865,
          "lng": 78.036021
        },
        {
          "name": "Gaya",
          "lat": 24.796858,
          "lng": 85.003852
        },
        {
          "name": "Sīkar",
          "lat": 27.614778,
          "lng": 75.138671
        },
        {
          "name": "Tumkūr",
          "lat": 13.341358,
          "lng": 77.102203
        },
        {
          "name": "Kollam",
          "lat": 8.881131,
          "lng": 76.584694
        },
        {
          "name": "Ahmadnagar",
          "lat": 19.094571,
          "lng": 74.738432
        },
        {
          "name": "Bhīlwāra",
          "lat": 25.347071,
          "lng": 74.640812
        },
        {
          "name": "Nizāmābād",
          "lat": 18.673151,
          "lng": 78.10008
        },
        {
          "name": "Parbhani",
          "lat": 19.268553,
          "lng": 76.770807
        },
        {
          "name": "Shillong",
          "lat": 25.573987,
          "lng": 91.896807
        },
        {
          "name": "Lātūr",
          "lat": 18.399487,
          "lng": 76.584252
        },
        {
          "name": "Rājapālaiyam",
          "lat": 9.451111,
          "lng": 77.556121
        },
        {
          "name": "Bhāgalpur",
          "lat": 25.244462,
          "lng": 86.971832
        },
        {
          "name": "Muzaffarnagar",
          "lat": 29.470914,
          "lng": 77.703324
        },
        {
          "name": "Muzaffarpur",
          "lat": 26.122593,
          "lng": 85.390553
        },
        {
          "name": "Mathura",
          "lat": 27.503501,
          "lng": 77.672145
        },
        {
          "name": "Patiāla",
          "lat": 30.336245,
          "lng": 76.392199
        },
        {
          "name": "Saugor",
          "lat": 23.838766,
          "lng": 78.738738
        },
        {
          "name": "Brahmapur",
          "lat": 19.311514,
          "lng": 84.792903
        },
        {
          "name": "Shāhbāzpur",
          "lat": 27.874116,
          "lng": 79.879327
        },
        {
          "name": "New Delhi",
          "lat": 28.6,
          "lng": 77.2
        },
        {
          "name": "Rohtak",
          "lat": 28.894473,
          "lng": 76.589166
        },
        {
          "name": "Samlaipādar",
          "lat": 21.478072,
          "lng": 83.990505
        },
        {
          "name": "Ratlām",
          "lat": 23.330331,
          "lng": 75.040315
        },
        {
          "name": "Fīrozābād",
          "lat": 27.150917,
          "lng": 78.397808
        },
        {
          "name": "Rājahmundry",
          "lat": 17.005171,
          "lng": 81.777839
        },
        {
          "name": "Barddhamān",
          "lat": 23.255716,
          "lng": 87.856906
        },
        {
          "name": "Bīdar",
          "lat": 17.913309,
          "lng": 77.530105
        },
        {
          "name": "Bamanpurī",
          "lat": 28.804495,
          "lng": 79.040305
        },
        {
          "name": "Kākināda",
          "lat": 16.960361,
          "lng": 82.238086
        },
        {
          "name": "Pānīpat",
          "lat": 29.387471,
          "lng": 76.968246
        },
        {
          "name": "Khammam",
          "lat": 17.247672,
          "lng": 80.143682
        },
        {
          "name": "Bhuj",
          "lat": 23.253972,
          "lng": 69.669281
        },
        {
          "name": "Karīmnagar",
          "lat": 18.436738,
          "lng": 79.13222
        },
        {
          "name": "Tirupati",
          "lat": 13.635505,
          "lng": 79.419888
        },
        {
          "name": "Hospet",
          "lat": 15.269537,
          "lng": 76.387103
        },
        {
          "name": "Chikka Mandya",
          "lat": 12.545602,
          "lng": 76.895078
        },
        {
          "name": "Alwar",
          "lat": 27.566291,
          "lng": 76.610202
        },
        {
          "name": "Aizawl",
          "lat": 23.736701,
          "lng": 92.714596
        },
        {
          "name": "Bijāpur",
          "lat": 16.827715,
          "lng": 75.718988
        },
        {
          "name": "Imphal",
          "lat": 24.808053,
          "lng": 93.944203
        },
        {
          "name": "Tharati Etawah",
          "lat": 26.758236,
          "lng": 79.014875
        },
        {
          "name": "Rāichūr",
          "lat": 16.205459,
          "lng": 77.35567
        },
        {
          "name": "Pathānkot",
          "lat": 32.274842,
          "lng": 75.652865
        },
        {
          "name": "Chīrāla",
          "lat": 15.823849,
          "lng": 80.352187
        },
        {
          "name": "Sonīpat",
          "lat": 28.994778,
          "lng": 77.019375
        },
        {
          "name": "Mirzāpur",
          "lat": 25.144902,
          "lng": 82.565335
        },
        {
          "name": "Hāpur",
          "lat": 28.729845,
          "lng": 77.780681
        },
        {
          "name": "Porbandar",
          "lat": 21.641346,
          "lng": 69.600868
        },
        {
          "name": "Bharatpur",
          "lat": 27.215251,
          "lng": 77.492786
        },
        {
          "name": "Puducherry",
          "lat": 11.933812,
          "lng": 79.829792
        },
        {
          "name": "Karnāl",
          "lat": 29.691971,
          "lng": 76.984483
        },
        {
          "name": "Nāgercoil",
          "lat": 8.177313,
          "lng": 77.43437
        },
        {
          "name": "Thanjāvūr",
          "lat": 10.785233,
          "lng": 79.139093
        },
        {
          "name": "Pāli",
          "lat": 25.775125,
          "lng": 73.320611
        },
        {
          "name": "Agartala",
          "lat": 23.836049,
          "lng": 91.279386
        },
        {
          "name": "Ongole",
          "lat": 15.503565,
          "lng": 80.044541
        },
        {
          "name": "Puri",
          "lat": 19.798254,
          "lng": 85.824938
        },
        {
          "name": "Dindigul",
          "lat": 10.362853,
          "lng": 77.975827
        },
        {
          "name": "Haldia",
          "lat": 22.025278,
          "lng": 88.058333
        },
        {
          "name": "Bulandshahr",
          "lat": 28.403922,
          "lng": 77.857731
        },
        {
          "name": "Purnea",
          "lat": 25.776703,
          "lng": 87.473655
        },
        {
          "name": "Proddatūr",
          "lat": 14.7502,
          "lng": 78.548129
        },
        {
          "name": "Gurgaon",
          "lat": 28.460105,
          "lng": 77.026352
        },
        {
          "name": "Khānāpur",
          "lat": 21.273716,
          "lng": 76.117376
        },
        {
          "name": "Machilīpatnam",
          "lat": 16.187466,
          "lng": 81.13888
        },
        {
          "name": "Bhiwāni",
          "lat": 28.793044,
          "lng": 76.13968
        },
        {
          "name": "Nandyāl",
          "lat": 15.477994,
          "lng": 78.483605
        },
        {
          "name": "Bhusāval",
          "lat": 21.043649,
          "lng": 75.785058
        },
        {
          "name": "Bharauri",
          "lat": 27.598203,
          "lng": 81.694709
        },
        {
          "name": "Tonk",
          "lat": 26.168672,
          "lng": 75.786111
        },
        {
          "name": "Sirsa",
          "lat": 29.534893,
          "lng": 75.028981
        },
        {
          "name": "Vizianagaram",
          "lat": 18.11329,
          "lng": 83.397743
        },
        {
          "name": "Vellore",
          "lat": 12.905769,
          "lng": 79.137104
        },
        {
          "name": "Alappuzha",
          "lat": 9.494647,
          "lng": 76.331108
        },
        {
          "name": "Shimla",
          "lat": 31.104423,
          "lng": 77.166623
        },
        {
          "name": "Hindupur",
          "lat": 13.828065,
          "lng": 77.491425
        },
        {
          "name": "Bāramūla",
          "lat": 34.209004,
          "lng": 74.342853
        },
        {
          "name": "Bakshpur",
          "lat": 25.894283,
          "lng": 80.792104
        },
        {
          "name": "Dibrugarh",
          "lat": 27.479888,
          "lng": 94.90837
        },
        {
          "name": "Saidāpur",
          "lat": 27.598784,
          "lng": 80.75089
        },
        {
          "name": "Navsāri",
          "lat": 20.85,
          "lng": 72.916667
        },
        {
          "name": "Budaun",
          "lat": 28.038114,
          "lng": 79.126677
        },
        {
          "name": "Cuddalore",
          "lat": 11.746289,
          "lng": 79.764362
        },
        {
          "name": "Harīpur",
          "lat": 31.463218,
          "lng": 75.986418
        },
        {
          "name": "Krishnāpuram",
          "lat": 12.869617,
          "lng": 79.719469
        },
        {
          "name": "Fyzābād",
          "lat": 26.775486,
          "lng": 82.150182
        },
        {
          "name": "Silchar",
          "lat": 24.827327,
          "lng": 92.797868
        },
        {
          "name": "Ambāla",
          "lat": 30.360993,
          "lng": 76.797819
        },
        {
          "name": "Krishnanagar",
          "lat": 23.405761,
          "lng": 88.490733
        },
        {
          "name": "Kolār",
          "lat": 13.137679,
          "lng": 78.129989
        },
        {
          "name": "Kumbakonam",
          "lat": 10.959789,
          "lng": 79.377472
        },
        {
          "name": "Tiruvannāmalai",
          "lat": 12.230204,
          "lng": 79.072954
        },
        {
          "name": "Pīlibhīt",
          "lat": 28.631245,
          "lng": 79.804362
        },
        {
          "name": "Abohar",
          "lat": 30.144533,
          "lng": 74.19552
        },
        {
          "name": "Port Blair",
          "lat": 11.666667,
          "lng": 92.75
        },
        {
          "name": "Alīpur Duār",
          "lat": 26.4835,
          "lng": 89.522855
        },
        {
          "name": "Hatīsa",
          "lat": 27.592698,
          "lng": 78.013843
        },
        {
          "name": "Vālpārai",
          "lat": 10.325163,
          "lng": 76.955299
        },
        {
          "name": "Aurangābād",
          "lat": 24.752037,
          "lng": 84.374202
        },
        {
          "name": "Kohima",
          "lat": 25.674673,
          "lng": 94.110988
        },
        {
          "name": "Gangtok",
          "lat": 27.325739,
          "lng": 88.612155
        },
        {
          "name": "Karūr",
          "lat": 10.960277,
          "lng": 78.076753
        },
        {
          "name": "Jorhāt",
          "lat": 26.757509,
          "lng": 94.203055
        },
        {
          "name": "Panaji",
          "lat": 15.498289,
          "lng": 73.824541
        },
        {
          "name": "Saidpur",
          "lat": 34.318174,
          "lng": 74.457093
        },
        {
          "name": "Tezpur",
          "lat": 26.633333,
          "lng": 92.8
        },
        {
          "name": "Itanagar",
          "lat": 27.102349,
          "lng": 93.692047
        },
        {
          "name": "Daman",
          "lat": 20.414315,
          "lng": 72.83236
        },
        {
          "name": "Silvassa",
          "lat": 20.273855,
          "lng": 72.996728
        },
        {
          "name": "Diu",
          "lat": 20.715115,
          "lng": 70.987952
        },
        {
          "name": "Dispur",
          "lat": 26.135638,
          "lng": 91.800688
        },
        {
          "name": "Kavaratti",
          "lat": 10.566667,
          "lng": 72.616667
        },
        {
          "name": "Calicut",
          "lat": 11.248016,
          "lng": 75.780402
        },
        {
          "name": "Kagaznāgār",
          "lat": 19.331589,
          "lng": 79.466051
        },
        {
          "name": "Jaipur",
          "lat": 26.913312,
          "lng": 75.787872
        },
        {
          "name": "Ghandinagar",
          "lat": 23.216667,
          "lng": 72.683333
        },
        {
          "name": "Panchkula",
          "lat": 30.691512,
          "lng": 76.853736
        }
      ];
      // locations1=[];
      // return array[index];
      this.center1 = array[index];
      // this.locations1.push(array[index]);
    },
    submitMessage() {
      const CHAT_ROOM = this.form.roomName;
      const message = this.inputMessageText;
      SocketioService.sendMessage({ message, roomName: CHAT_ROOM }, (cb) => {
        console.log(cb);
        this.messages.push({
          message,
          ...SENDER,
        });
        // clear the input after the message is sent
        this.inputMessageText = "";
      });
    },
    async submitToken() {
      console.log(this.form);
      let header = {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
      };
      let Promise = axios.post("http://65.1.73.254:8090/", this.form, {
        header: header,
      });
      Promise.then((res) => {
        if (res.status == 200) {
          console.log(res);
          this.token = res.data.token;
          //   alert(this.token);
          SocketioService.setupSocketConnection(this.token);
          SocketioService.subscribeToMessages((err, data) => {
            console.log("position data", data);
            this.messages.push(data);
            data = JSON.parse(data)
            // console.log(JSON.parse(data));
            // this.center1.lat=data.lat;
            // this.center1.lng=data.lng;
            this.center1 = { "lat": data.lat, "lng": data.lng }
            this.polyline.push(this.center1);
            localStorage.setItem('polyline', this.polyline);
            this.polyline1 = new google.maps.Polyline(this.polyline).getPath();
            console.log(this.polyline1)
            this.random = this.random + 1;
            this.polypath = {
              path: this.polyline,
              geodesic: true,
              strokeColor: 'blue',
              strokeOpacity: 0.5,
              strokeWeight: 5,
            }
          });
        }
      });
    },
    getRandNumber() {
      var x = Math.floor((Math.random() * 214) + 1); // get new random number
      if (x === this.lastNumber) { // compare with last number
        return this.getRandNumber() // if they are the same, call the function again to repeat the process
      }
      return x // if they're not the same, return it
    },
    getRideData(id) {
      var data = { ride_id: id };
      var promise = apis.get_RideData(data);
      promise
        .then(response => {
          this.list = [];
          this.list = response.data;
          if (this.list.length > 0) {
            var length = this.list.length;
            this.center = { lat: parseFloat(this.list[0].lat), lng: parseFloat(this.list[0].lng) };
            this.center1 = { lat: parseFloat(this.list[length - 1].lat), lng: parseFloat(this.list[length - 1].lng) };
            var data = [];
            for (let x = 0; x < length; x++) {
              if (this.list[x].lat && this.list[x].lng) {
                data.push({ lat: parseFloat(this.list[x].lat), lng: parseFloat(this.list[x].lng) });
              }
            }
            //this.polypath = {path:data}
            this.polypath = {
              path: data,
              geodesic: true,
              strokeColor: 'blue',
              strokeOpacity: 0.5,
              strokeWeight: 5,
            }
          }
        }
        )
    },
    async view_details(location) {
      // this.center1= await this.getrandomLatlong(await this.getRandNumber());
      console.log(123, await this.getRandNumber());
      this.home = false;
      this.centerstart = { lat: location.lat, lng: location.lng }
      //await  mapRef.value.map.panTo({ lat: 0, lng: lng.value });
      // alert(location._id)
      this.form.roomName = location._id;
      this.form.userName = location.user_id;
      // this.polyline.push(location);
      console.log("🚀 ~ file: Dashboard.vue:1819 ~ location:", location)
      this.mapstops = location.group_stops ? location.group_stops : [];
      this.getRideData(location._id)
      await this.submitToken();
      // await this.submitMessage();
    },
    back_map() {
      this.home = true;
      socketioService.disconnect();
    },
    show_route() {
      var screensize = document.querySelectorAll("#maph")
      screensize[0].style.display = "none";
      screensize[0].style.display = "block";
      this.show_map = false;
      this.show_map = true;
      this.home = true;
      this.home = false;
    },
    get_location_details(location) {
      // alert(location.name)
    },
    setupLeafletMap: function () {
      const mapDiv = L.map("mapContainer").setView([37, 7749, -122, 4194], 13);
      L.tileLayer(
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: "mapbox/streets-v11",
          token: "sk.eyJ1IjoicHJhdGhhbWVzaDExMDkiLCJhIjoiY2xidzBzZnVxMXB4ODNwcWRkOGRqOGluaiJ9.4K08iC1KLCqL6MOFcioywg",
        }
      ).addTo(mapDiv);
    },
    load_map() {
      // let btn = document.getElementById('map');
      map = '';
      var map = new MapmyIndia.Map("map", { center: [28.09, 78.3], zoom: 5, search: false });
    },
    go_to_student() {	//alert(987)
      this.$router.push('/studentmaster');
    },
    go_to_teacher() {
      this.$router.push('/teachermaster');
    },
    go_to_subject() {
      this.$router.push('/usermaster');
    },
    goto_alert() {
      this.$router.push('/sosalertmaster');
    },
    go_to_user() {
      this.$router.push('/userqueriesmaster');
    },
    go_to_net_connect() {
      this.$router.push('/userqueriesmaster');
    },
    go_to_data_usage() {
      this.$router.push('/userqueriesmaster');
    },
    go_to_news() {
      this.$router.push('/NewsMaster/6');
    },
    go_to_Feedback() {
      this.$router.push('/userqueriesmaster');
    },
    go_to_Scemes() {
      this.$router.push('/SchemeMaster/10');
    },
    go_to_Notice() {
      this.$router.push('/NoticeMaster');
    },
    route_list() {
      var data = {
        count: false,
        client_id: localStorage.getItem("client_id") ? localStorage.getItem("client_id") : ''
      };
      this.loading = true;
      var promise = apis.group_list(data, this.id);
      promise.then((response) => {
        this.loading = false;
        if (response.data.data.length > 0) {
          response.data.data
            .filter(item => item.name)
            .map(item => this.routeitems.push({ name: item.name, value: item._id }));
        }
      });
    },
    get_client_list() {
      var data = { count: false ,
        user_id: localStorage.getItem("id"),

      };
      var promise = apis.getclientist(data);
      promise.then((response) => {
        if (response.data.data) {
          response.data.data
            .filter(item => item.client_name)
            .map(item => this.client_id_dropdown.push({ name: item.client_name, value: item._id }));
        }
      });
    },
    async dashboardRideData() {
      var data = { client_id: localStorage.getItem("client_id") ? localStorage.getItem("client_id") : '' };
      var promise = apis.dashboardRideData(data);
      promise
        .then(response => {
          this.barData.labels = response.data.data.series;
          this.barData.datasets[0].data = response.data.data.data;
        });
    },
    async dashboardcount() {
      var data = { client_id: localStorage.getItem("client_id") ? localStorage.getItem("client_id") : '' };
      var promise = apis.dashboardcount(data);
      promise
        .then(response => {
          // alert(1);
          // console.log(this.barData.datasets[0].data);
          var other_data = response.data.other_data;
          this.dashcount.today_travels = other_data.todays_travel;
          this.dashcount.travels = other_data.total_travel;
          this.dashcount.schools = other_data.schools;
          this.dashcount.school_buses = other_data.school_buses;
          this.dashcount.alerts = other_data.alerts;
          this.dashcount.today_alerts = other_data.todays_alerts;
          this.dashcount.vehicles = other_data.vehicles;
          // alert(other_data.total_travel)
          var data = response.data.data;
          // alert(data.length);
          var count = data.length;
          // alert(count);
          var i = 0;
          // for(i=0;i<data.length;i++)
          // {
          //   // alert(i)
          // 	if(data[i].user_type=='Driver')
          // 	{
          // 		this.dashcount.drivers+=data[i].count;
          // 	}
          // 	if(data[i].user_type=='Owner')
          // 	{
          // 		this.dashcount.owners+=data[i].count;
          // 	}
          // 	if(data[i].user_type=='Rider')
          // 	{
          // 		this.dashcount.riders+=data[i].count;
          // 	}
          // 	if(data[i].user_type=='Driver Owner')
          // 	{
          // 		this.dashcount.driverowner=data[i].count;
          // 	}
          // 	if(data[i].user_type=='Admin')
          // 	{
          // 		this.dashcount.admin=data[i].count;
          // 	}
          // }
          // if(i==(count-1))
          // {
          // alert('here');
          // alert(this.dashcount.riders);
          // alert(i);
          this.dashcount.alerts = response.data.other_data.alerts;
          this.dashcount.today_alerts = response.data.other_data.todays_alerts;
          this.pieData = {
            labels: response.data.other_data.user_counts.labels,
            datasets: [
              {
                data: response.data.other_data.user_counts.series_data,
                backgroundColor: [
                  "#F04136",
                  "#2B3890",
                  "#F7941E",
                  "#F9ED32",
                  "#D7F422",
                  "#DE6BBC",
                  "#DEDD59",
                  "#58DEDF"
                ],
                hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#662D91",
                  "#F8F194",
                  "#58DEDF",
                  "#949597",
                  "#F8F194"
                ]
              }
            ]
          };
          this.pieData1 = {
            labels: response.data.other_data.vehicle_group.labels,
            datasets: [
              {
                data: response.data.other_data.vehicle_group.series_data,
                backgroundColor: [
                  "#F04136",
                  "#2B3890",
                  "#F7941E",
                  "#39B54A",
                  "#D7F422",
                  "#DE6BBC",
                  "#DEDD59",
                  "#58DEDF"
                ],
                hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#662D91",
                  "#F8F194",
                  "#58DEDF",
                  "#949597",
                  "#DEDD59"
                ]
              }
            ]
          };
          // }
          // this.dashcount=response.data;
        });
    },
    networkconnectivityreport() {
      var D = new Date(this.get_date_2);
      if (this.get_date_2) {
        var date = D.toLocaleDateString("en-US", { year: "numeric" }) + "-" + D.toLocaleDateString("en-US", { month: "2-digit" }) + "-" + D.toLocaleDateString("en-US", { day: "2-digit" });
      }
      var data = { date: date };
      var promise = apis.networkconnectivityreport(data);
      promise
        .then(response => {
          this.lineData.datasets[0].data = Object.values(response.data);
        });
    },
    datausagereport() {
      var D = new Date(this.get_date);
      if (this.get_date) {
        var date = D.toLocaleDateString("en-US", { year: "numeric" }) + "-" + D.toLocaleDateString("en-US", { month: "2-digit" }) + "-" + D.toLocaleDateString("en-US", { day: "2-digit" });
      }
      var data = { date: date };
      var promise = apis.dashboarddatausagereport(data);
      promise
        .then(response => {
          // alert(1);
          // console.log(this.barData.datasets[0].data);
          this.barData.datasets[0].data = Object.values(response.data);
        });
    },
    teacherlecturereportadmin() {
      var promise = apis.teacherlecturereportadmin();
      promise
        .then(response => {
          // alert(1);
          // console.log(this.barData.datasets[0].data);
          this.barData1.labels = response.data.teacher;
          this.barData1.datasets[0].data = response.data.lectures;
        });
    },
    todayslecturereport() {
      var data = {
        "limit": 5,
        "page_no": 1
      }
      var promise = apis.todayslecturereport(data);
      promise
        .then(response => {
          // alert(1);
          // console.log(this.barData.datasets[0].data);
          this.todayslectures = response.data;
        });
    },
    formatCurrency(value) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.barOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
        }
      };
      this.barOptions1 = {
        indexAxis: 'y',
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
        }
      };
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
        }
      };
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#ebedef'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#ebedef'
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#ebedef'
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
        }
      };
    }
  },
  components: {
    Tabs,
    Tab,
    TabPanels,
    superset_dashboard,
    DashboardComponent,
    GoogleMapDashboard,
    TabPanel,
    GoogleMap, Marker, MarkerCluster, InfoWindow, GMapMarker, Polyline, GoogleMapComponent,
    "l-map": LMap,
    LTileLayer,
    LMarker
  }
}
</script>
<style>
/* .layout-topbar,.layout-sidebar{ */
/* background-color:#274461 !important;
}
.layout-topbar .layout-topbar-button{
  color: white;
}
.layout-menu li a{
  color: white;
}
.layout-topbar-logo a{
  color: white;
} */
/* .layout-main-container{
    background-color: black !important;
}
.layout-wrapper{
    background-color: black !important;
} */
</style>
<style scoped>
#mapContainer {
  width: 100%;
  height: 100vh;
}

.p-button-sm {
  font-size: 0.7rem;
  padding: 0.4rem 0.8rem;
}
.active {
    /* background-color: #22C55E; */
    border-bottom: 4px solid #274461;
    /* Change this to your desired color */
    color: black;
    /* Text color for active tab */
    /* border-radius: 10px; */
    font-weight: bold;
}
.tab:hover {
  font-weight: bold;
  border-bottom: 4px solid #274461;
}
.inactive {
    /* background-color: #374151; */
    /* Change this to your desired color */
    color: black;
    /* Text color for inactive tab */
    /* border-radius: 10px; */
    /* font-weight: bold; */
}

.card {
    cursor: pointer;
}
</style>