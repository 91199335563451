<template>
    <div
        class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <Toast />
        <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
        <div class="flex flex-column align-items-center justify-content-center">

            <div style="
          border-radius: 56px;
          padding: 0.3rem;
          
        ">
                <div class="w-full surface-card py-7 px-5 sm:px-8 blue_shadow" style="border-radius: 53px">
                    <div class="text-center">

                        <div style="color: #6b719b; font-size: 30px; font-weight: 800"> {{ $t('login') }}</div>
                    </div>

                    <div>
                        <div>
                            <label for="email1" class="block text-900 text-xl font-medium mb-2">{{ $t('email') }}</label>
                            <InputText id="email1" type="text" placeholder="Email address"
                                class="w-full md:w-30rem mb-3" style="padding: 1rem" 
                               
                             v-model="email" />
                        </div>

                        <div>
                            <!-- <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <InputText id="password1" v-model="password" type="password" class="w-full mb-3"  :toggleMask="true" 
                            placeholder="Password" style="padding: 1rem" @keypress.enter="Login" /> -->



                            <label for="password1" class="block text-900 font-medium text-xl mb-2">{{ $t('password') }}</label>
                            <Password id="password1" v-model="password"   :placeholder="$t('password')" 
                             :toggleMask="true" :feedback="false"
                              
                                class="w-full mb-3" inputClass="w-full" :inputStyle="{ padding: '1rem' }"
                                @keypress.enter="Login"></Password>
          
                          
                        </div>
                        <div class="flex align-items-center justify-content-end mb-5 gap-5">
                            <!-- <div class="flex align-items-center">
                                <Checkbox v-model="checked" id="rememberme1" binary class="mr-2"></Checkbox>
                                <label for="rememberme1">Remember me</label>
                            </div> -->
                            <!-- <a class="font-medium no-underline ml-2 text-right cursor-pointer"  @click="showForgotPasswordDialog"
                                style="color: var(--primary-color)">Forgot password?</a> -->
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer"
                                @click="showForgotPasswordDialog"
                                style="color: var(--primary-color); text-decoration: none;">
                                {{ $t('forgot_pass') }} ?
                            </a>

                        </div>
                        <Button  :label="$t('sign_in')"  class="w-full p-3 text-xl btn_light_blue" @click="Login"></Button>
                    </div>
                </div>

            </div>
            <Dialog v-model:visible="showModal" header="Forgot Password" :modal="true" :closable="true"
                :dismissableMask="false" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" style="width: 40vw">
                <template #header>
                    <h5>{{ $t('forgot_pass') }}</h5>
                </template>

                <template #default>
                    <Loader v-if="isLoading" />
                    <form>
                       
                        <div class="p-field">
                            <label for="username" class="block text-900 font-medium text-xl mb-2">{{ $t('email') }}</label>
                            <InputText class="w-full mb-2" :disabled="fieldsDisabled"
                           v-model="EmailID" id="username"
                           :placeholder="$t('enter_username')"  />
                        </div>

                    

                       
                        <div class="p-field">
                            <label for="mobileno" class="block text-900 font-medium text-xl mb-2">{{ $t('mob_no') }}</label>
                            <InputText class="w-full mb-2" maxlength="10"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                :disabled="mobileDisabled"
                              v-model="mobile" id="mobile" :placeholder="$t('enter_mobile_no')" />
                        </div>

                        <!-- Send OTP Button --> 
                        <div class="p-d-flex p-jc-end">
                            <Button v-if="!otpSent"   :label="$t('send_otp')"  @click="SendOTP" class="p-button" style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #b05800 !important;
                    background-color: #b05800 !important;
                    margin-left: 60%;
                  " />&nbsp;
                            <div v-if="loading" class="spinner-container">
                                <i class="pi pi-spin pi-spinner"></i> {{ $t('sending') }}
                            </div>
                            <Button v-if="!otpSent" label="Reset" @click="resetForm"
                                class="p-button p-ml-2 custom-green-button" style="
                                                box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                                                border-color: #00b0b0 !important;
                                                background-color: #00b0b0 !important;
                                            " />
                        </div>







                        <div v-if="otpSent">


                            <div class="p-field">
                                <label for="otp" class="block text-900 font-medium text-xl mb-2">{{ $t('enter_otp') }}</label>
                                <InputText class="w-full mb-3" :disabled="otpDisabled" v-model="otp" id="otp"
                                :placeholder="$t('enter_otp')"  />
                            </div>

                            <div class="p-d-flex p-jc-end" v-if="!otpVerified">
                                <Button   :label="$t('verify_otp')"  @click="verifyOtp" class="p-button" />
                            </div>

                            <!-- New Password and Confirm Password Fields -->
                            <div v-if="otpVerified">


                                <!-- <div class="p-field">
                            <label  for="password" class="block text-700 font-medium text-xl mb-2"> Old Password</label><br>
                            <InputText  class="w-full mb-3" v-model="old_password" id="password" placeholder="Enter Old Password" />
                        </div> -->

                                <div class="p-field">
                                    <label for="password" class="block text-900 font-medium text-xl mb-2">
                                        {{ $t('new_pass') }}</label>
                                   
                                        <Password id="password" v-model="new_pass"  
                                          @input="validatePassword"
                                         :placeholder="$t('enter_new_password')"  :toggleMask="true" :feedback="false"
                                class="w-full mb-3" inputClass="w-full" :inputStyle="{ padding: '1rem' }"
                                ></Password>
                                <div v-if="passwordError" class="text-red-500 text-sm mb-3">
                                    {{ passwordError }}
                                </div>
                                </div>




                                <div class="p-field">
                                    <label for="confirm-password"
                                        class="block text-900 font-medium text-xl mb-2">{{ $t('conf_pass') }}</label>

                                        <Password id="password" v-model="conf_pass"  
                                          @input="validatePassword"
                                          :placeholder="$t('enter_confirm_password')" 

                                       :toggleMask="true" :feedback="false"
                                class="w-full mb-3" inputClass="w-full" :inputStyle="{ padding: '1rem' }"
                                ></Password>
                                        <div v-if="passError" class="text-red-500 text-sm mb-3">
                                                {{ passError }}
                                            </div>
                                   
                                </div>
                             

                                <div class="p-d-flex p-jc-end">
                                    <Button   :label="$t('submit')"  class="p-button p-button-sm" @click="resetPassword" />
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </Dialog>

        </div>
    </div>
</template>
<script>
import apis from "@/apis";
export default {
    data() {
        return {
            old_password: "",
            passwordError: "",
            passwordError:"",
            passError:"",
            passError: "",
            EmailID: "",
            mobile: "",
            new_pass: "",
            showModal: false,
            isLoading: "",
            user_name: "",
            mobile: "",
            otp: "",
            new_pass: "",
            conf_pass: "",
            otpSent: "",
            otpVerified: "",


            passwordError: "",


            isLoadingModel: false,
            fieldsDisabled: false,
            otpDisabled: false,
            mobileDisabled: false,
            email: "",
            password: "",
            newpassword: "",
            fullPage: true,
            email: "",
            password: "",
            checked: false,
            show: false,
            user_type: "",
            dropdownItems: [
                { name: "Admin", value: "admin" },
                { name: "Teacher", value: "teacher" },
                { name: "Assistent Teacher", value: "assteacher" },
                { name: "Student", value: "student" },
                { name: "Network Administrator", value: "network" },
            ],
        };
    },
    methods: {
            validatePassword() {
      
                    this.passwordError = '';
                    this.passError = '';

                    
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
                    if (!passwordRegex.test(this.new_pass)) {
                        //this.passwordError = "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.";
                        this.passwordError = this.$t('password_validation_error');
                        return false;
                    }

                
                    if (this.new_pass !== this.conf_pass) {
                       // this.passError = "Password and Confirm Password do not match.";
                        this.passError = this.$t('password_match_error');
                        return false;
                    }

                    
                    

                   
                    return true;
                },

                resetForm() {
                    this.EmailID = "";
                    this.mobile = "";

                },

             SendOTP() {



            if (this.EmailID == '' || this.EmailID == undefined) {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                
                    detail: this.$t('email_empty_error'), 
                    life: 3000,
                });
                return false;
            }

            if (this.EmailID) {
                var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
                if (!emailreg.test(this.EmailID)) {
                    this.$toast.add({
                        severity: "error",
                        summary: "error",
                      
                        detail: this.$t('email_invalid_error'), 
                        life: 3000,
                    });
                    return false;
                }
            }

            if (!this.mobile) {
                this.$toast.add({

                    severity: "error",
                    summary: "error",
                   // detail: "Mobile number is required. Please enter a valid number",
                    detail: this.$t('mobile_empty_error'),
                 
                    life: 3000,
                });
                return false;
            }
            if (this.mobile == '' || this.mobile == undefined) {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    //detail: "Please enter mobile number",
                    detail: this.$t('mobile_required_error'),
                    life: 3000,
                });
                return false;
            }
            if (/^0+$/.test(this.mobile) || /^0/.test(this.mobile)) {
                // Condition: Mobile number contains all zeros or starts with zero
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Mobile number cannot start with zero or contain all zeros.",

                    detail: this.$t('mobile_zero_error'), 
                  
                    life: 3000,
                });
                return false;
            }
                    if (!/^\d{10}$/.test(this.mobile)) {
            
                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                            detail: "Please enter a valid 10-digit mobile number.",
                            detail: this.$t('mobile_invalid_error'),
                          
                            life: 3000,
                        });
                        return false;
                    }

                 const data = {
                        email_id: this.EmailID,
                        mobile_number: this.mobile,

                    };

            this.loading = true;


            apis
                .sendotp(data)
                .then((response) => {
                    this.loading = false;
                    if (response.data.status) {

                        this.$toast.add({
                            severity: "success",
                            summary: "Success",
                            detail: response.data.message,
                            life: 3000,
                        });

                        this.otpSent = true;
                        this.fieldsDisabled = true;
                        this.mobileDisabled = true;
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                            detail: response.data.message,
                            life: 3000,
                        });
                    }
                })
                .catch((error) => {
                    this.loading = false;


                });
        },
        async verifyOtp() {
        
           
            if (this.otp == '' || this.otp == undefined) {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: this.$t('otp_empty_error'),
                    life: 3000,
                });
                return false;
            }
            const data = {
                mobile_number: this.mobile,
                otp: this.otp,
            };


            this.loading = true;

            try {

                const response = await apis.verifyOtp(data);

                this.loading = false;

                if (response.data.status == true) {
                    // this.$swal("success", response.data.message);
                    this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: response.data.message,
                        life: 3000,
                    });
                    this.otpVerified = true;
                    this.otpDisabled = true;
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: response.data.message,
                        life: 3000,
                    });
                }
            } catch (error) {
                this.loading = false;
                console.error("Error verifying OTP:", error);


                const errorMessage = error.response?.data?.message
                    || "An error occurred while verifying the OTP. Please try again.";
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: errorMessage,
                    life: 3000,
                });
            }
        },

        async resetPassword() {

            this.passwordError = '';
            this.passError = '';

               if (!this.new_pass || this.new_pass == '') {
                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                          
                            detail: this.$t('new_password_empty_error'), 
                            life: 3000,
                        });
                        return false;
                        }
           

                    if (!this.conf_pass) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                           
                            detail: this.$t('confirm_password_empty_error'),
                            life: 3000,
                        });
                        return;
                    }


        

   
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        if (!passwordRegex.test(this.new_pass)) {
           // this.passwordError = "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.";
           this.passwordError = this.$t('password_validation_error');
            return false;
        }
      
   
        if (this.new_pass !== this.conf_pass) {
           // this.passError = "Password and Confirm Password do not match.";
            this.passError = this.$t('password_match_error');
            return false;
        }

        
            var data = {
                email_id: this.EmailID,
                mobile_number: this.mobile,

                new_password: this.new_pass,
            };


            this.loading = true;

            try {
                // API call
                const response = await apis.resetnewPassword(data);

                this.loading = false;

                if (response.data.status) {

                    this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: response.data.message,
                        life: 3000,
                    });

                    this.clearForm();

                    this.showModal = false;
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: response.data.message || "Failed to reset the password.",
                        life: 3000,
                    });

                }
            } catch (error) {
                this.loading = false;

                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                  
                    detail: this.$t('reset_password_error'),
                    life: 3000,
                });
            }
        },


        clearForm() {

        },



        showForgotPasswordDialog() {
      
            //   alert(111);
            this.EmailID = "";
             this.mobile = "";
            this.showModal = true;
            this.otpSent = false;

        },
        disableAction(event) {
            event.preventDefault();
        },
        validatePhoneInput(event) {
            const key = event.keyCode || event.which; // Use keyCode or which
            if (key < 48 || key > 57) {
                event.preventDefault(); // Prevent non-numeric characters
            }
        },
        Forgot_password() {
            if (this.mobile.length > 10 || this.mobile.length < 10) {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    // detail: "Please Enter Valid Mobile Number",
                    detail: this.$t('mobile_invalid_length_error'),
                    life: 3000,
                });
                return false;
            }

            if (this.mobile.length !== 10) {
                // Condition: Mobile number length is not 10
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: this.$t('mobile_invalid_error'),
                   // detail: "Mobile number must be exactly 10 digits.",
                    life: 3000,
                });
                return false;
            }

            if (!/^\d+$/.test(this.mobile)) {
                // Condition: Mobile number contains non-numeric characters
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                   // detail: "Mobile number must contain only digits.",
                    detail: this.$t('mobile_numeric_error'),
                    life: 3000,
                });
                return false;
            }

            if (/^0+$/.test(this.mobile) || /^0/.test(this.mobile)) {
                // Condition: Mobile number contains all zeros or starts with zero
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: this.$t('mobile_zero_error'), 
                    life: 3000,
                });
                return false;
            }

        },
        Cancel_password() {

            this.$refs.del.hide();
        },
        delete_toggle() {

            this.$refs.del.toggle(event);
        },
        async Login() {
            if (
                this.email === "" ||
                this.email === null ||
                this.email === undefined
            ) {
                //alert("Please Enter Username");
                this.$toast.add({
                    severity: "error",
                    summary: "Error Message",
                    detail: this.$t('please_enter_username'),
                   // detail: "Please Enter Username",
                    life: 3000,
                });
                return false;
            }

           

            if (
                this.password === "" ||
                this.password === null ||
                this.password === undefined
            ) {
                //alert("Please Enter Username");
                this.$toast.add({
                    severity: "error",
                    summary: "Error Message",
                    detail: "Please enter your password.",
                    life: 3000,
                });
                return false;
            }

            var data = {
                email_id: this.email,
                password: this.password,
            };
            this.isLoadingModel = true;
            var promise = apis.login(data);
            promise
                .then((response) => {
                    this.isLoadingModel = false;
                    if (response.data.status == true) {
                        localStorage.clear();


                        localStorage.name = response.data.data[0].name;
                        localStorage.email_id = response.data.data[0].email_id;
                        localStorage.mobile_number = response.data.data[0].mobile_number;
                        localStorage.profile_image = response.data.data[0].profile_image;
                        localStorage.email_notification = response.data.data[0].email_notification;


                        localStorage.user_type = response.data.data[0].user_type;
                        localStorage.id = response.data.data[0]._id;
                        localStorage.token = response.data.data[0].logintoken;
                        localStorage.client_id = response.data.data[0].client_id
                            ? response.data.data[0].client_id
                            : "";
                        //for fleet manager
                        localStorage.role_id = response.data.data[0].role_id[0].role_id;
                        localStorage.setItem(
                            "user_profile",
                            JSON.stringify(response.data.data[0])
                        );
                        var dashboard_link = "";
                        if (response.data.data[0].role_id && response.data.data[0].role_id[0].dashboard_link) {
                            dashboard_link = response.data.data[0].role_id[0].dashboard_link.toString();
                            localStorage.Breadcrumb_home = dashboard_link;  //for Breadcrumb_home
                            this.$router.push({ name: dashboard_link });
                        }
                        else {
                            dashboard_link = '/dashboard';                    //for Breadcrumb_home
                            localStorage.Breadcrumb_home = dashboard_link;    //for Breadcrumb_home
                            this.$router.push('/dashboard');
                        }
                        //this.$router.push("/dashboard");


                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Error Message",
                            detail: response.data.message,
                            life: 3000,
                        });
                        return false;
                    }
                })
                .catch((error) => {
                    this.isLoadingModel = false;
                    this.$toast.add({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Invalid Login Credentials",
                        life: 3000,
                    });
                    return false;
                });
        },
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return "white";
            return "dark";
        },
    },
};
</script>
<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}

#logo {
    margin-bottom: 1rem !important;
    width: 15%;
}

.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: #007bff;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}
</style>